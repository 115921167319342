import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabThuesim/taotientrinh";
import NhatKy from "./tabThuesim/nhatky";

import { changeTab, loadThuesim } from '../../actions/ThuesimActions';

class Thuesim extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
		loadThuesim: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTab(0);
	};
	onClick = (value) => {
		this.props.changeTab(value);
		this.props.loadThuesim();
	};
	render() {
		const { changetab, loading } = this.props.Thuesim;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="card-header-cus border-bottom">
						<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/thuesim" onClick={() => { this.onClick(0) }} >
									Tạo Tiến Trình
								</Link>
							</li>
							{/* <li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/thuesim" onClick={() => { this.onClick(1) }} >
									Danh Sách Order
								</Link>
							</li> */}
						</ul>
					</div>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left show active"/*  + (changetab === 0 ? "show active": "" ) */} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
					</div>
				</div>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left show active"/*  + (changetab === 1 ? "show active": "" ) */} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Thuesim: state.Thuesim,
});
const mapDispatchToProps = { changeTab, loadThuesim };

export default connect(mapStateToProps, mapDispatchToProps)(Thuesim);
