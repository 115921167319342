import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Header from "./Header";
import Body from "./Body";
import Navbar from "./Navbar";
import Login from "./auth/Login";
import Login1 from "./auth/Login1";
import Login2 from "./auth/Login2";
import Login3 from "./auth/Login3";
import Login4 from "./auth/Login4";
import Login5 from "./auth/Login5";
import Login6 from "./auth/Login6";
import Login7 from "./auth/Login7";
import Login8 from "./auth/Login8";
import Login9 from "./auth/Login9";
import Login10 from "./auth/Login10";
import Daily from "./Daily";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isMobile } from 'react-device-detect';
import { changeSidebar,getDataBtConfigBgNav } from "../actions/authActions";
import { FcOnlineSupport } from "react-icons/fc";

class Maincontainer extends Component {
	componentDidMount() {
		var config_website = {checktime: 0};
		try {
			if (localStorage.getItem("confignavbar") != null) {
				config_website = JSON.parse(localStorage.getItem("confignavbar"));
			}
			if (config_website.checktime == 0 || (parseInt(Date.now() / 1000) - config_website.checktime > 3600)) {
				this.props.getDataBtConfigBgNav();
			}
		} catch(e) {
			this.props.getDataBtConfigBgNav();
		}
 	}
  onClickSidebar = (isOpen) => {
	  this.props.changeSidebar(!isOpen);
  }
  render() {
	// document.title = this.props.auth.config_website.cf_title;
	var theme_color = document.querySelectorAll("[name='theme-color']");
	if (theme_color.length > 0) {
		theme_color[0].parentNode.removeChild(theme_color[0]);
	}
	var title = document.querySelectorAll("title");
	if (title.length > 0) {
		title[0].parentNode.removeChild(title[0]);
	}
	var icon = document.querySelectorAll("[rel='icon']");
	if (icon.length > 0) {
		icon[0].parentNode.removeChild(icon[0]);
	}
	var description = document.querySelectorAll("[name='description']");
	if (description.length > 0) {
		description[0].parentNode.removeChild(description[0]);
	}
	var og_title = document.querySelectorAll("[property='og:title']");
	if (og_title.length > 0) {
		og_title[0].parentNode.removeChild(og_title[0]);
	}
	var og_site_name = document.querySelectorAll("[property='og:site_name']");
	if (og_site_name.length > 0) {
		og_site_name[0].parentNode.removeChild(og_site_name[0]);
	}
	var og_description = document.querySelectorAll("[property='og:description']");
	if (og_description.length > 0) {
		og_description[0].parentNode.removeChild(og_description[0]);
	}
	var og_type = document.querySelectorAll("[property='og:type']");
	if (og_type.length > 0) {
		og_type[0].parentNode.removeChild(og_type[0]);
	}
	var og_url = document.querySelectorAll("[property='og:url']");
	if (og_url.length > 0) {
		og_url[0].parentNode.removeChild(og_url[0]);
	}
	var og_keywords = document.querySelectorAll("[property='og:keywords']");
	if (og_keywords.length > 0) {
		og_keywords[0].parentNode.removeChild(og_keywords[0]);
	}
	var og_image = document.querySelectorAll("[property='og:image']");
	if (og_image.length > 0) {
		og_image[0].parentNode.removeChild(og_image[0]);
	}
	var config_website = {};
	try {
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
	} catch(e) {
		// this.props.getDataBtConfigBgNav();
	}
	var linkEl = [];
	linkEl.push(<meta name="theme-color" content={"#" + config_website.bg_navbar} />);
	linkEl.push(<title>{config_website.cf_title}</title>);
	linkEl.push(<link rel="icon" href={config_website.cf_icon} />);
	linkEl.push(<meta name="description" content={config_website.cf_description} />);
	linkEl.push(<meta property="og:title" content={config_website.cf_og_title} />);
	linkEl.push(<meta property="og:site_name" content={config_website.cf_og_site_name} />);
	linkEl.push(<meta property="og:description" content={config_website.cf_og_description} />);
	linkEl.push(<meta property="og:type" content={config_website.cf_og_type} />);
	linkEl.push(<meta property="og:url" content={config_website.cf_og_url} />);
	linkEl.push(<meta property="og:keywords" content={config_website.cf_og_keywords} />);
	linkEl.push(<meta property="og:image" content={config_website.cf_og_image} />);
	var linkElAsDirectChildOfHead = ReactDOM.createPortal(linkEl, document.head);
	ReactDOM.render(linkElAsDirectChildOfHead, document.createElement("div"));
	
	var fb_customerchat = document.querySelectorAll("[class='fb-customerchat']");
	if (fb_customerchat.length > 0) {
		fb_customerchat[0].parentNode.removeChild(fb_customerchat[0]);
	}
	var linkEl = [];
	linkEl.push(<div className="fb-customerchat" attribution="setup_tool" page_id={config_website.support_id_fanpage}></div>);
	var linkElAsDirectChildOfHead = ReactDOM.createPortal(linkEl, document.body);
	ReactDOM.render(linkElAsDirectChildOfHead, document.createElement("div"));
	
    let isAuthenticated = this.props.auth.isAuthenticated;
    var isOpenSidebar = this.props.auth.isOpenSidebar;
    let isDaily = this.props.auth.isDaily;
	// console.log(isOpenSidebar);
    return (
		<Fragment>
			<style>
				{"\
				body .bt-bg-navbar{\
					background:#"+config_website.bg_navbar+"!important;\
				}\
				body #page-container.page-header-dark #page-header{\
					background:#fff!important;\
				}\
				body .page-header-dark #page-header .btn-dual:focus, body .page-header-dark #page-header .btn-dual:hover{\
					background:#fff!important;\
					border-color:#fff!important;\
				}\
				body .page-header-dark #page-header #google_translate_element:hover{\
					background:#fff!important;\
					border-color:#fff!important;\
				}\
				body .page-header-dark #page-header .dropdown-item.thongtin:active{\
					background:#"+config_website.bg_navbar+"!important;\
				}\
				.licus a {\
					/*border: 1px solid #"+config_website.bg_navbar+"!important;\
					color: #"+config_website.bg_navbar+"!important;*/\
					color: #495057!important;\
				}\
				.licus .active {\
					background-color: #"+config_website.bg_navbar+"!important;\
				}\
				.sidebar-o-xs .wrap-logo-mobile {\
					background-color: #fff!important;\
				}\
				body .svg-hotro a {\
					background: #"+config_website.bg_navbar+"!important;\
				}\
				body .badge.badge-cus.position-absolute-cus {\
					background: #"+config_website.bg_navbar+"!important;\
				}\
				body .btn-show-description-cus {\
					background: #"+config_website.bg_navbar+"!important;\
				}\
				body #sidebar {\
					background: #"+config_website.bg_navbar+"!important;\
				}\
				body .quanlycusba .card-congratulations {\
					background: linear-gradient(118deg, #"+config_website.bg_navbar+", #"+config_website.bg_navbar+"b3) !important;\
				}\
				body .quanlycusba .badge.badge-light-primary {\
					background-color: #"+config_website.bg_navbar+"1f !important;\
					color: #"+config_website.bg_navbar+" !important;\
				}\
				"}
				{/* body .icon-hotro {\
					background: #"+config_website.bg_navbar+"!important;\
					color: #"+config_website.bg_navbar+"!important;\
				}\ */}
				{
					(isOpenSidebar && isMobile) ?
						"\
						.sidebar-o-xs-mobile#page-container.page-header-fixed #page-header {\
							left: 0\
						}\
						"
					: ""
				}
				{
					(!isOpenSidebar && isMobile) ?
						"\
						.sidebar-o-xs #sidebar {\
							-webkit-transform: translateX(0) translateY(0) translateZ(0);\
							transform: translateX(0) translateY(0) translateZ(0);\
						}\
						#page-container.side-scroll #sidebar .content-side {\
							width: 100% !important;\
						}\
						"
					: ""
				}
				{
					isMobile ?
						"@media (max-width:3200px){.fb_iframe_widget.fb_invisible_flow{display: none;}}"
					: ""
				}
			</style>
			{ isDaily === 0 ? (
				<Fragment>
					{
						window.location.pathname.indexOf('/services') > -1 ? (
							<Fragment>
								<Router>
									{
										config_website.landingpage == 1 || typeof config_website.landingpage === 'undefined' ?
											<Login typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 2 ?
											<Login1 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 3 ?
											<Login2 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 4 ?
											<Login3 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 5 ?
											<Login4 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 6 && (window.location.hostname === 'hacklikepro.net' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost') ?
											<Login5 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 7 && (window.location.hostname === 'fbtouse.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
											<Login6 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 8 && (window.location.hostname === 'dv.banvia.net' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
											<Login7 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 9 && (window.location.hostname === 'thegioifanpage.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
											<Login8 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 10 && (window.location.hostname === 'vipfb18.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
											<Login9 typeform="3" />
										: ''
									}
									{
										config_website.landingpage == 11 && (window.location.hostname === 'ctvsubvn.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
											<Login10 typeform="3" />
										: ''
									}
							</Router>
						</Fragment>
					) : isAuthenticated ? (
						<div
						id="page-container"
						className={"enable-page-overlay side-scroll page-header-fixed page-header-dark main-content-narrow side-trans-enabled"+ (isOpenSidebar && !isMobile? ' sidebar-o' : '') + (!isOpenSidebar? ' sidebar-o-xs' : '') + (isMobile? ' sidebar-o-xs-mobile' : '')}
						style={ (!isOpenSidebar && isMobile) ? {"position":"fixed"} : {"position":"inherit"} }
						>
							<Router>
								<Navbar />
								<Header />
								<Body />
								<div id="hotro">
									<div className="wrap-hotro">
										<div className="icon-hotro">
											<span className="svg-hotro">
												<a href="/support/add">
													{/* <FcOnlineSupport style={{"height": "4em", "width": "4em"}} /> */}
													<span className="font-bold" style={{"verticalAlign": "middle"}}>
														<i className="far fa-life-ring"></i> Gửi Yêu Cầu Hỗ Trợ
													</span>
												</a>
											</span>
										</div>
									</div>
								</div>
								<div className="backgroundnavbar" onClick={() => {this.onClickSidebar(isOpenSidebar)}}></div>
							</Router>
						</div>
					) : (
						<Fragment>
							<Router>
								<div style={{'display': 'none'}}>
									<Header />
								</div>
								{
									config_website.landingpage == 1 || typeof config_website.landingpage === 'undefined' ?
										<Login />
									: ''
								}
								{
									config_website.landingpage == 2 ?
										<Login1 />
									: ''
								}
								{
									config_website.landingpage == 3 ?
										<Login2 />
									: ''
								}
								{
									config_website.landingpage == 4 ?
										<Login3 />
									: ''
								}
								{
									config_website.landingpage == 5 ?
										<Login4 />
									: ''
								}
								{
									config_website.landingpage == 6 && (window.location.hostname === 'hacklikepro.net' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost') ?
										<Login5 />
									: ''
								}
								{
									config_website.landingpage == 7 && (window.location.hostname === 'fbtouse.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
										<Login6 />
									: ''
								}
								{
									config_website.landingpage == 8 && (window.location.hostname === 'dv.banvia.net' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
										<Login7 />
									: ''
								}
								{
									config_website.landingpage == 9 && (window.location.hostname === 'thegioifanpage.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
										<Login8 />
									: ''
								}
								{
									config_website.landingpage == 10 && (window.location.hostname === 'vipfb18.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
										<Login9 />
									: ''
								}
								{
									config_website.landingpage == 11 && (window.location.hostname === 'ctvsubvn.com' || window.location.hostname === 'tesst.golike.vn' || window.location.hostname === 'localhost')  ?
										<Login10 />
									: ''
								}
							</Router>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Daily />
			)}
		</Fragment>
    );
  }
}

Maincontainer.propTypes = {
  auth: PropTypes.object.isRequired,
  changeSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {changeSidebar,getDataBtConfigBgNav})(Maincontainer);
