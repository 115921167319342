import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addVV, updateVV, getConfigMoDong } from '../../../actions/VimeoviewActions';
import Swal from 'sweetalert2';

class taotientrinh extends Component {
	static propTypes =  {
		addVV: PropTypes.func.isRequired,
		updateVV: PropTypes.func.isRequired,
	};
	
	componentDidMount() {
		this.props.getConfigMoDong();
	};
	
    getIDVimeo(t) {
        let e = null;
        if (t) {
            if (t.indexOf("vimeo.com") < 0) e = t;
            else {
                var s = t.match(/vimeo.com\/(.*)\//),
					a = t.match(/vimeo.com\/(.*)/),
					b = t.match(/vimeo.com\/(.*)\?/);
                if (b !== null) {
					return b[1];
                } else if (s !== null) {
                    return s[1];
                } else if (a !== null) {
					return a[1];
				}
            }
            return e
        }
    }
	
	onChange = (e) => {
		var { taotientrinh } = this.props.Vimeoview;
		var new_taotientrinh = {
			...taotientrinh,
			[e.target.name]: e.target.value
		};
		if (e.target.name === 'lhi') {
			var id_stt = this.getIDVimeo(e.target.value);
			var new_taotientrinh = {
				...taotientrinh,
				"lhi": id_stt
			};
		} else if (e.target.name === "lsct") {
			if (e.target.value === "sv1") {
				var value = this.props.Vimeoview.price.Vimeoview.prices_web;
				taotientrinh = {
					...taotientrinh,
					"gtmtt": value,
				};
			}
			// if (e.target.value === "sv2") {
				// var value = this.props.Vimeoview.price.Vimeoview_sv2.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			// if (e.target.value === "sv3") {
				// var value = this.props.Vimeoview.price.Vimeoview_sv3.prices_web;
				// taotientrinh = {
					// ...taotientrinh,
					// "gtmtt": value,
				// };
			// }
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
			};
		}
		this.props.updateVV(new_taotientrinh);
	}
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Vimeoview;
		const id_user = this.props.auth.user.id;
		if (taotientrinh.lvctm === "") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'ID không được để trống',
			});
			return false;
		}
		var new_taotientrinh = {
			...taotientrinh,
			'type_api': 'vimeoview'
		};
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua "+taotientrinh.slct+" view với giá "+taotientrinh.gtmtt+" Coin / View?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addVV(new_taotientrinh);
			}
		})
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	render() {
		const { lhi, lsct, slct, gc, gtmtt } = this.props.Vimeoview.taotientrinh;
		const { vimeobuffview_mo_dong } = this.props.Vimeoview;
		var price = slct * gtmtt;
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Link hoặc ID cần tăng:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="lhi" name="lhi" placeholder="" onChange={this.onChange} value={lhi} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Chọn Máy Chủ cần tăng:</label>
					<div className="col-sm-8">
						<div className="custom-control custom-radio custom-control-inline" style={{opacity: this.props.Vimeoview.price.Vimeoview.prices_web == 0 ? '0.3' : '1' }}>
							<input onChange={this.onChange} value="sv1" type="radio" className="custom-control-input" id="loaiseeding_1" name="lsct" checked={lsct.toString() === "sv1"} disabled={this.props.Vimeoview.price.Vimeoview.prices_web == 0 ? 'disabled' : ''}/>
							<label className="custom-control-label" htmlFor="loaiseeding_1" >SV1 (View Global) <span className="badge badge-primary" style={{"fontSize": "0.92rem"}}>{this.props.Vimeoview.price.Vimeoview.prices_web} coin</span> {vimeobuffview_mo_dong == 1 ? <span className="text-success">(Đang mở)</span> : <span className="text-danger">(Đang đóng)</span> }</label>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Số lượng cần tăng:</label>
					<div className="col-md-8">
						<div className="form-group">
                            <input type="number" className="form-control" onChange={this.onChange} name="slct" value={slct} onKeyPress={this.onKeyPress}/>
                        </div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Giá tiền mỗi tương tác:
					</label>
					<div className="col-sm-8">
						<input type="number" className="form-control" id="gtmtt" name="gtmtt" placeholder="" onChange={this.onChange} value={gtmtt} onKeyPress={this.onKeyPress} />
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor=""></label>
				    <div className="col-md-8 bold">
				        <div className="card card-orange">
				        	<div className="card-body py-2">
								<h3 className="mb-0 text-danger">HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!</h3>
								<ul>
									<li>1 ID KHÔNG CÀI 2 ĐƠN CÙNG LÚC TRONG HỆ THỐNG ! ĐƠN CŨ XONG MỚI CÀI ĐƠN MỚI ! CỐ TÌNH CÀI BỊ HỤT SỐ LƯỢNG SẼ KHÔNG XỬ LÝ !</li>
									<li>Nếu ID đang chạy trên hệ thống mà bạn vẫn mua id đó cá hệ thống bên khác, nếu có tình trạng hụt số lượng giữa 2 bên thì sẽ không được xử lí!</li>
									<li>Không thể hoàn tiền khi hủy đơn!</li>
									<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình để được hỗ trợ tốt nhất!</li>
								</ul>
			        		</div>
		        		</div>
				    </div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea type="text" className="form-control" id="gc" name="gc" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" onChange={this.onChange} value={gc} ></textarea>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0">Bạn sẽ mua <span className="green bold">{slct} view</span> với giá <span className="green bold">{gtmtt}</span>  Coin / View</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Vimeoview: state.Vimeoview,
});
const mapDispatchToProps = { addVV, updateVV, getConfigMoDong }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);