import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabyoutubeview/taotientrinh";
import NhatKy from "./tabyoutubeview/nhatky";

import { changeTab, loadYV } from '../../actions/youtubeviewActions';
import { load, changeTabYoutube } from '../../actions/youtubeActions';

class Youtubeview extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
		load: PropTypes.func.isRequired,
		changeTabYoutube: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTabYoutube('youtubeview', 0);
	};
	onClick = (value) => {
		this.props.changeTabYoutube('youtubeview', value);
		// this.props.loadYV();
		this.props.load('youtubeview');
	};
	render() {
		const { changetab, loading } = this.props.youtubeview;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="card-header-cus border-bottom">
						<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/youtube-view" onClick={() => { this.onClick(0) }} >
									Tạo Tiến Trình
								</Link>
							</li>
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/youtube-view" onClick={() => { this.onClick(1) }} >
									Danh Sách Order
								</Link>
							</li>
						</ul>
					</div>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	youtubeview: state.youtubeview,
});
const mapDispatchToProps = { changeTab, loadYV, load, changeTabYoutube };

export default connect(mapStateToProps, mapDispatchToProps)(Youtubeview);
