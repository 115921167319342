import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import Taotientrinh from "./item_viplike_group/taotientrinh";
import Nhatkyorder from "./item_viplike_group/nhatkyorder";

class Viplikeclone extends Component {

	state = {
        checkTab: 0
    }

    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            checkTab: data,
        });
    }

  	render() {
  		const {checkTab} = this.state;
  		
	    return (
	    	<Fragment>
	    		<div className="row">
	                <div className="col-md-12">
	                    <div className={"block block-bordered shadow" + (this.props.buffsub.loadingblock? ' block-mode-loading' : '')}>
							<div className="card-header-cus border-bottom">
								<ul className="nav nav-tabs nav-tabs-block">
									<li onClick={(e) => {this.openTabMenu(e, 0)}} className="nav-item zxc licus">
										<a className={'nav-link'+ (checkTab === 0 ? ' active' : '')} href="/#">
											Tạo Tiến Trình
										</a>
									</li>
									<li onClick={(e) => {this.openTabMenu(e, 1)}} className="nav-item zxc licus">
										<a className={'nav-link' + (checkTab === 1 ? ' active' : '')} href="/#">
											Danh Sách Order
										</a>
									</li>
								</ul>
							</div>
	                        <div className="block-content tab-content overflow-hidden">
	                            {checkTab === 0 ? 
	                                <div className='tab-pane fade fade-left show active' id="taotientrinh" >
	                                    <Taotientrinh />
	                                </div>
	                            : 
	                                <div className='tab-pane fade fade-left show active show active' id="nhatkyorder" >
	                                    <Nhatkyorder />
	                                </div>
	                            }
	                        </div>
	                    </div>
	                </div>
	            </div>
	    	</Fragment>
		);
  	}
}

const mapStateToProps = state => ({
    buffsub: state.buffviplikegroup,
});

export default connect(mapStateToProps, {}) (Viplikeclone);
