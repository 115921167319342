import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import { addFBB, updateFBB, dangnhapFBB, loadPriceFBB, CheckCookie } from '../../../actions/FacebookbotActions';
import { add, checkCookieFb } from '../../../actions/FbbotActions';
import { LOADING_FBB } from "../../../actions/types";
import parse from 'html-react-parser';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFBB: PropTypes.func.isRequired,
		updateFBB: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		checkCookieFb: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
	};
	
	state = {
		"dntkfcc": ""
	}
	
	/*componentDidMount() {
		this.props.loadPriceFBB();
	};*/
	
	option_tgctt = () => {
		let ar_option_tgctt = [];

		for (let i = 0; i < 24; i++) {
			ar_option_tgctt.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_tgctt;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookbot;
		if (e.target.name === "lnncx_type") {
			var type = taotientrinh.lnncx_type;
			if (type.indexOf(e.target.value) === -1) {
				type.push(e.target.value);
			} else {
				var position = type.indexOf(e.target.value);
				type.splice(position, 1);
			}
			var new_taotientrinh = {
				...taotientrinh,
				"lnncx_type": type
			};
			this.props.updateFBB(new_taotientrinh);
		} else if (e.target.name === "ctkfcc") {
			var fun_check = this.props.checkCookieFb(e.target.value);
			var cookie_ctkfcc = e.target.value;
			store.dispatch({
				type: LOADING_FBB,
				payload: true
			});
			if (fun_check !== false) {
				fun_check.then(res => {
					if (res.data.status === 200) {
						let data_res = res.data.data;
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": data_res.uid,
							"usernamefb": data_res.name,
							"ctkfcc": cookie_ctkfcc,
							"dtsg": data_res.dtsg,
						};
						this.props.updateFBB(new_taotientrinh);
						store.dispatch({
							type: LOADING_FBB,
							payload: false
						});
						Swal.fire({
							icon: 'success',
							html: 'Lấy thông tin thành công'
						});
					} else {
						store.dispatch({
							type: LOADING_FBB,
							payload: false
						});
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: 'Cookie die hoặc không hợp lệ',
						});
						return false;
					}
				})
			} else {
				store.dispatch({
					type: LOADING_FBB,
					payload: true
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #1',
				});
				store.dispatch({
					type: LOADING_FBB,
					payload: false
				});
				return false;
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateFBB(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookbot;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.ctkfcc === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Cookie tài khoản facebook cần chạy không được để trống',
			});
			return false;
		}
		if (parseInt(taotientrinh.tgctt_tu) > parseInt(taotientrinh.tgctt_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian chạy tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		if (parseInt(taotientrinh.tgdctt_tu) > parseInt(taotientrinh.tgdctt_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian delay chuyển tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		if ((parseInt(taotientrinh.blbv_tu) > parseInt(taotientrinh.blbv_den)) && (taotientrinh.blbv === "1")) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Icon bình luận bài viết từ phải nhỏ hơn đến',
			});
			return false;
		}
		if (taotientrinh.lnncx === "1" && taotientrinh.lnncx_type.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Bạn phải chọn cảm xúc ít nhất 1 cảm xúc!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua chạy Auto tương tác trong "+taotientrinh.snmcatt+" ngày với giá "+taotientrinh.gtmtt+" Coin / ngày?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookbot', new_taotientrinh);
			}
		})
	}
	
	onChangeDangNhap = (e) => {
		var dangnhap = {
			"dntkfcc": this.state.dntkfcc
		};
		this.props.dangnhapFBB(dangnhap);
	}
	
	onChangedntkfcc = (e) => {
		this.setState({
			"dntkfcc": e.target.value
		});
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	render() {
		const { ctkfcc, tgctt_tu, tgctt_den, tgdctt_tu, tgdctt_den, lnncx, lnncx_type, lnncx_tdmn, blbv, blbv_tu, blbv_den, blbv_tdmn, tdcbb, tdcbb_tdmn, tdglmkb, tdglmkb_tdmn, tdcnlmkb, tdcnlmkb_tdmn, snmcatt, gtmtt, idfb, usernamefb } = this.props.Facebookbot.taotientrinh;
		var price = (snmcatt * gtmtt);
		var info = '';
		if (idfb !== '') {
			info = parse('<img src="https://graph.facebook.com/'+idfb+'/picture?height=100&amp;width=100" class="avatar-user" /> ' + usernamefb + ' (' + idfb + ')');
		}
		return (
			<Fragment>
				{/*<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Đăng nhập tài khoản facebook cần chạy:</label>
					<div className="col-sm-6">
						<input type="text" className="form-control" id="dntkfcc" name="dntkfcc" placeholder="username|password" onChange={this.onChangedntkfcc} />
						<h6 className="mb-0 font-13 text-muted">Nhập tài khoản Facebook theo định dạng <span className="text-danger">username|password</span></h6>
					</div>
					<div className="col-sm-2">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.onChangeDangNhap} >Lấy Cookie</button>
					</div>
				</div>*/}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Cookie tài khoản facebook cần chạy:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="ctkfcc" name="ctkfcc" placeholder="" onChange={this.onChange} />
						{info}
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian chạy tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_tu" name="tgctt_tu" onChange={this.onChange} value={tgctt_tu} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_den" name="tgctt_den" onChange={this.onChange} value={tgctt_den} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian delay chuyển tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_tu" name="tgdctt_tu" className="form-control input-light" onChange={this.onChange} value={tgdctt_tu} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_den" name="tgdctt_den" className="form-control input-light" onChange={this.onChange} value={tgdctt_den} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
						</div>
					</div>
				</div>
				<h5 className="bold mb-0">Cài đặt tương tác:</h5>
				<div className="row mt-2">
					<div className="col-md-4">
						 <div className="custom-control custom-checkbox custom-control-inline">
							<input value={lnncx === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="lnncx" name="lnncx" onChange={this.onChange} checked={lnncx === "1"} />
							<label className="custom-control-label" htmlFor="lnncx" >Like ngẫu nhiên cảm xúc:</label>
						</div>
						<h6 className="mb-0 font-13 text-danger">Có thể chọn nhiều loại cảm xúc</h6>
					</div>
					<div className="col-md-8">
						<div className="card card-gray">
							<div className="card-body py-2">
								<div className="form-group">
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="like" checked={lnncx_type.indexOf("like") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb"  style={{"width": "52px"}}>
										<input style={{display: 'none'}} value="care" checked={lnncx_type.indexOf("care") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="love" checked={lnncx_type.indexOf("love") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="haha" checked={lnncx_type.indexOf("haha") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="wow" checked={lnncx_type.indexOf("wow") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="sad" checked={lnncx_type.indexOf("sad") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="angry" checked={lnncx_type.indexOf("angry") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
								</div>
								<div className="form-group" style={{ "marginBottom" : "0px", "display": lnncx === '1' ? 'flex' : 'none' }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="lnncx_tdmn" name="lnncx_tdmn" className="form-control input-light" onChange={this.onChange} value={lnncx_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Cảm xúc</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						 <div className="custom-control custom-checkbox custom-control-inline">
							<input value={blbv === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="blbv" name="blbv" onChange={this.onChange} checked={blbv === "1"} />
							<label className="custom-control-label" htmlFor="blbv" >Bình luận bài viết:</label>
						</div>
						<h6 className="mb-0 font-13">Nên chọn ngẫu nhiên từ 1 đến 5</h6>
					</div>
					<div className="col-md-8">
						<div className="card card-gray">
							<div className="card-body py-2">
								<div className="form-group">
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="bold mb-0">Từ:</h6>
										</div>
										<div className="col">
											<input type="number" id="blbv_tu" name="blbv_tu" className="form-control input-light" onChange={this.onChange} value={blbv_tu} onKeyPress={this.onKeyPress} />
										</div>
										<div className="col-auto">
											<h6 className="bold mb-0">đến</h6>
										</div>
										<div className="col">
											<input type="number" id="blbv_den" name="blbv_den" className="form-control input-light" onChange={this.onChange} value={blbv_den} onKeyPress={this.onKeyPress} />
										</div>
										<div className="col-auto pl-0">
											<h6 className="bold mb-0">Icon</h6>
										</div>
									</div>
								</div>
								<div className="form-group" style={{ "marginBottom" : "0px", "display": blbv === '1' ? 'flex' : 'none' }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="blbv_tdmn" name="blbv_tdmn" className="form-control input-light" onChange={this.onChange} value={blbv_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Cảm xúc</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<div className="form-group">
							<div className="custom-control custom-checkbox custom-control-inline">
								<input value={tdcbb === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="tdcbb" name="tdcbb" onChange={this.onChange} checked={tdcbb === "1"} />
								<label className="custom-control-label" htmlFor="tdcbb" >Tự động chọc bạn bè:</label>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="card card-gray" style={{ "display": tdcbb === '1' ? 'flex' : 'none' }}>
							<div className="card-body py-2">
								<div className="form-group" style={{ "marginBottom" : "0px" }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="tdcbb_tdmn" name="tdcbb_tdmn" className="form-control input-light" onChange={this.onChange} value={tdcbb_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Lượt chọc</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<div className="form-group">
							<div className="custom-control custom-checkbox custom-control-inline">
								<input value={tdglmkb === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="tdglmkb" name="tdglmkb" onChange={this.onChange} checked={tdglmkb === "1"} />
								<label className="custom-control-label" htmlFor="tdglmkb" >Tự động gửi lời mời kết bạn:</label>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="card card-gray" style={{ "display": tdglmkb === '1' ? 'flex' : 'none' }}>
							<div className="card-body py-2">
								<div className="form-group" style={{ "marginBottom" : "0px" }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="tdglmkb_tdmn" name="tdglmkb_tdmn" className="form-control input-light" onChange={this.onChange} value={tdglmkb_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Gửi lời mời</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<div className="form-group">
							<div className="custom-control custom-checkbox custom-control-inline">
								<input value={tdcnlmkb === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="tdcnlmkb" name="tdcnlmkb" onChange={this.onChange} checked={tdcnlmkb === "1"} />
								<label className="custom-control-label" htmlFor="tdcnlmkb" >Tự động chấp nhận lời mời kết bạn:</label>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="card card-gray" style={{ "display": tdcnlmkb === '1' ? 'flex' : 'none' }}>
							<div className="card-body py-2">
								<div className="form-group" style={{ "marginBottom" : "0px" }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="tdcnlmkb_tdmn" name="tdcnlmkb_tdmn" className="form-control input-light" onChange={this.onChange} value={tdcnlmkb_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Chấp nhận</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Số ngày mua chạy Auto tương tác:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="snmcatt" name="snmcatt" onChange={this.onChange} value={snmcatt} >
							<option value="10">10</option>
							<option value="30">30</option>
							<option value="60">60</option>
							<option value="90">90</option>
						</select>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ chạy Auto tương tác trong  <span className="green">{snmcatt} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / ngày</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookbot: state.Facebookbot,
});
const mapDispatchToProps = { addFBB, updateFBB, dangnhapFBB, loadPriceFBB, CheckCookie, add, checkCookieFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);