import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { listBankPayment, getlistBankInfoAdmin } from '../../../../actions/adminPageAction';
import copy from 'copy-text-to-clipboard';
import History from './history';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
class NapTien extends Component {

    state = {
        checkTab: 0
    }
    componentDidMount(){
		var listBankPayment_config = localStorage.getItem("listBankPayment");
		if (listBankPayment_config == null) {
			this.props.listBankPayment();
		} else {
			listBankPayment_config = JSON.parse(listBankPayment_config);
			if (typeof listBankPayment_config.checktime === 'undefined') {
				this.props.listBankPayment();
			} else {
				if ((parseInt(Date.now() / 1000) - listBankPayment_config.checktime > 1)) {
					this.props.listBankPayment();
				}
			}
		}
        this.props.getlistBankInfoAdmin();
    }
    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            checkTab: data,
        });
    }
    copyID(text) {
        copy(text);
        Swal.fire({
            icon: 'success',
            title: '',
            text: 'Đã copy!',
        });
        return false;
    }
    render() {
        const {checkTab} = this.state;
        const {loadding} = this.props.buffviewsvideo;
        const { listbankinfo } = this.props.adminpage;
		var listbankpayment = {};
		if (localStorage.getItem("listBankPayment") != null) {
			listbankpayment = JSON.parse(localStorage.getItem("listBankPayment"));
		} else {
			listbankpayment = this.props.adminpage.listbankpayment;
		}
		var config_website = {};
		if (localStorage.getItem("confignavbar") != null) {
			config_website = JSON.parse(localStorage.getItem("confignavbar"));
		} else {
			config_website = this.props.auth.config_website;
		}
        var cuphapnaptien = '';
        if (listbankpayment.noidung !== '') {
            cuphapnaptien = listbankpayment.noidung.replace('{user_name}',this.props.auth.user.username);
            cuphapnaptien = cuphapnaptien.replace('{id}',this.props.auth.user.id);
        }
		var html_bankinfo = [];
		var that = this;
		listbankinfo.forEach(function(v, i) {
			html_bankinfo.push(
				<div className="col-md-6" key={i}>
					<div className="form-group">
						<h3 className="bold" style={{"fontFamily":"monospace"}}>Nạp qua ngân hàng</h3>
						<div className="" style={{"borderRadius":"10px","background":"#F2F2F2","border":"1px solid #f3f5f9","display":"flex","flexDirection":"column","jujustifyContent":"center","alignItems":"center"}}>
							<div style={{"padding":"15px","maxWidth":"100%"}} className="col-md-6">
								<div className="d-flex flex-cus">
									<h6 className="col-md-4">Số tài khoản:</h6>
									<div className="col-md-6">
										<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
											<span className="bold">{v.sotaikhoan}</span>
										</h6>
									</div>
									<div className="col-md-2">
										<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{that.copyID(v.sotaikhoan)}}>
											COPY
										</button>
									</div>
								</div>
								<div className="d-flex flex-cus">
									<h6 className="col-md-4">Tên tài khoản:</h6>
									<div className="col-md-8">
										<h6 style={{"fontFamily":"monospace"}}>
											<span className="bold">{v.tentaikhoan}</span>
										</h6>
									</div>
								</div>
								<div className="d-flex flex-cus">
									<h6 className="col-md-4">Ngân hàng:</h6>
									<div className="col-md-8">
										<h6 style={{"fontFamily":"monospace"}}>
											<span className="bold">{v.nganhang}</span>
										</h6>
									</div>
								</div>
								<div className="d-flex flex-cus">
									<h6 className="col-md-4">Chi nhánh:</h6>
									<div className="col-md-8">
										<h6 style={{"fontFamily":"monospace"}}>
											<span className="bold">{v.chinhanh}</span>
										</h6>
									</div>
								</div>
								{
									v.gionapnhanh != '' && v.gionapnhanh != null ?
										(
											<div className="d-flex flex-cus">
												<h6 className="col-md-4">Giờ nạp nhanh:</h6>
												<div className="col-md-8">
													<h6 style={{"fontFamily":"monospace"}}>
														<span className="bold">{v.gionapnhanh}</span>
													</h6>
												</div>
											</div>
										)
									: ''
								}
								<div className="d-flex flex-cus">
									<h6 className="col-md-4">Nội dung nạp tiền:</h6>
									<div className="col-md-6">
										<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
											<span className="bold">{cuphapnaptien}</span>
										</h6>
									</div>
									<div className="col-md-2">
										<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{that.copyID(cuphapnaptien)}}>
											COPY
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		})
        return (
            <Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className={"block block-bordered shadow"+ (loadding? ' block-mode-loading' : '')}>
							<div className="card-header-cus border-bottom">
								<ul className="nav nav-tabs nav-tabs-block">
									<li onClick={(e) => {this.openTabMenu(e, 0)}} className="nav-item zxc licus">
										<a className={'nav-link'+ (checkTab === 0 ? ' active' : '')} href="/#">
											Nạp Tiền
										</a>
									</li>
									<li onClick={(e) => {this.openTabMenu(e, 1)}} className="nav-item zxc licus">
										<a className={'nav-link' + (checkTab === 1 ? ' active' : '')} href="/#">
											Nhật Ký Nạp Tiền
										</a>
									</li>
								</ul>
							</div>
                            <div className="block-content tab-content overflow-hidden">
                                {checkTab === 0 ? 
                                    <div className='tab-pane fade fade-left show active'>
										<div id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" className="tab-pane fade show active">
											<div className="col-md-12">
												<div className="col-md-12">
													<div className="form-group">
														{
															config_website.naptien_mota == "" ?
																<Fragment>
																	<h4 style={{"color":"#1FAB89"}}>Tỷ giá: 1 VND = 1 Coin (bạn nạp vào tài khoản bank hoặc momo của chúng tôi 100.000 vnđ bạn cũng sẽ nhận đc tương ứng 100.000 coin trong hệ thống )</h4>
																	<p style={{"color":"#1FAB89"}}>
																		Chính sách của hệ thống do admin quyết định!
																	</p>
																	<p style={{"color":"#1FAB89"}}>
																		Bạn vui lòng chuyển khoản chính xác nội dung chuyển khoản bên dưới hệ thống sẽ tự động cộng tiền cho bạn sau 1 phút sau khi nhận được tiền.
																		Nếu chuyển khác ngân hàng sẽ mất thời gian lâu hơn, tùy thời gian xử lý của mỗi ngân hàng.
																		Nếu sau 10 phút từ khi tiền trong tài khoản của bạn bị trừ mà bạn vẫn chưa được cộng tiền vui lòng nhấn vào đây để liên hệ hỗ trợ.
																	</p>
																</Fragment>
															: 
																<Fragment>
																	<h4 style={{"color":"#1FAB89", "whiteSpace": "pre-wrap"}}>{config_website.naptien_mota}</h4>
																</Fragment>
														}
													</div>
												</div>
												<div className="col-md-12 row">
													<div className="col-md-2">
														<div className="form-group">
															<h3><span className="text-danger bold">Nội dung nạp tiền:</span></h3>
														</div>
													</div>
													<div className="col-md-8">
														<div className="form-group">
															<input type="text" name="huongdan_1" className="form-control" value={cuphapnaptien} disabled />
														</div>
													</div>
													<div className="col-md-2">
														<div className="form-group">
															<button className="btn btn-primary" style={{"width": "100%"}} onClick={()=>{this.copyID(cuphapnaptien)}}>
																COPY
															</button>
														</div>
													</div>
												</div>
												<div className="col-md-12 row">
													<div className="col-md-6">
														<div className="form-group">
															<h3 className="bold" style={{"fontFamily":"monospace"}}>Nạp qua ngân hàng</h3>
															<div className="" style={{"borderRadius":"10px","background":"#F2F2F2","border":"1px solid #f3f5f9","display":"flex","flexDirection":"column","jujustifyContent":"center","alignItems":"center"}}>
																<div style={{"padding":"15px","maxWidth":"100%"}} className="col-md-6">
																	<div className="d-flex flex-cus">
																		<h6 className="col-md-4">Số tài khoản:</h6>
																		<div className="col-md-6">
																			<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
																				<span className="bold">{listbankpayment.sotaikhoan}</span>
																			</h6>
																		</div>
																		<div className="col-md-2">
																			<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{this.copyID(listbankpayment.sotaikhoan)}}>
																				COPY
																			</button>
																		</div>
																	</div>
																	<div className="d-flex flex-cus">
																		<h6 className="col-md-4">Tên tài khoản:</h6>
																		<div className="col-md-8">
																			<h6 style={{"fontFamily":"monospace"}}>
																				<span className="bold">{listbankpayment.tentaikhoan}</span>
																			</h6>
																		</div>
																	</div>
																	<div className="d-flex flex-cus">
																		<h6 className="col-md-4">Ngân hàng:</h6>
																		<div className="col-md-8">
																			<h6 style={{"fontFamily":"monospace"}}>
																				<span className="bold">{listbankpayment.nganhang}</span>
																			</h6>
																		</div>
																	</div>
																	<div className="d-flex flex-cus">
																		<h6 className="col-md-4">Chi nhánh:</h6>
																		<div className="col-md-8">
																			<h6 style={{"fontFamily":"monospace"}}>
																				<span className="bold">{listbankpayment.address_bank}</span>
																			</h6>
																		</div>
																	</div>
																	{/* <div className="d-flex flex-cus">
																		<h6 className="col-md-4">Giờ nạp nhanh:</h6>
																		<div className="col-md-8">
																			<h6 style={{"fontFamily":"monospace"}}>
																				<span className="bold">Từ 6h sáng đến 20h</span>
																			</h6>
																		</div>
																	</div> */}
																	<div className="d-flex flex-cus">
																		<h6 className="col-md-4">Nội dung nạp tiền:</h6>
																		<div className="col-md-6">
																			<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
																				<span className="bold">{cuphapnaptien}</span>
																			</h6>
																		</div>
																		<div className="col-md-2">
																			<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{this.copyID(cuphapnaptien)}}>
																				COPY
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{
														listbankpayment.tentaikhoan_momo != '' || listbankpayment.phone_momo != '' ?
															<div className="col-md-6">
																<div className="form-group">
																	<h3 className="bold" style={{"fontFamily":"monospace"}}>Nạp qua Momo</h3>
																	<div className="" style={{"borderRadius":"10px","background":"#F2F2F2","border":"1px solid #f3f5f9","display":"flex","flexDirection":"column","jujustifyContent":"center","alignItems":"center"}}>
																		<div style={{"padding":"15px","maxWidth":"100%"}} className="col-md-6">
																			<div className="d-flex flex-cus">
																				<h6 className="col-md-4">Tên tài khoản:</h6>
																				<div className="col-md-8">
																					<h6 style={{"fontFamily":"monospace"}}>
																						<span className="bold">{listbankpayment.tentaikhoan_momo}</span>
																					</h6>
																				</div>
																			</div>
																			<div className="d-flex flex-cus">
																				<h6 className="col-md-4">Số điện thoại:</h6>
																				<div className="col-md-6">
																					<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
																						<span className="bold">{listbankpayment.phone_momo}</span>
																					</h6>
																				</div>
																				<div className="col-md-2">
																					<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{this.copyID(listbankpayment.phone_momo)}}>
																						COPY
																					</button>
																				</div>
																			</div>
																			{/* <div className="d-flex flex-cus">
																				<h6 className="col-md-4">Số tiền tối thiểu:</h6>
																				<div className="col-md-8">
																					<h6 style={{"fontWeight":"600","fontSize":"20px"}}>
																						<span className="bold" >50.000 VNĐ</span>
																					</h6>
																				</div>
																			</div> */}
																			{/* <div className="d-flex flex-cus">
																				<h6 className="col-md-4">Giờ nạp nhanh:</h6>
																				<div className="col-md-8">
																					<h6 style={{"fontFamily":"monospace"}}>
																						<span className="bold">Từ 9h sáng đến 17h</span>
																					</h6>
																				</div>
																			</div> */}
																			<div className="d-flex flex-cus">
																				<h6 className="col-md-4">Nội dung nạp tiền:</h6>
																				<div className="col-md-6">
																					<h6 style={{"fontFamily":"monospace", "overflow-wrap": "anywhere"}}>
																						<span className="bold">{cuphapnaptien}</span>
																					</h6>
																				</div>
																				<div className="col-md-2">
																					<button className="btn btn-primary button-copy-stk-cus" onClick={()=>{this.copyID(cuphapnaptien)}}>
																						COPY
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														: ''
													}
													{html_bankinfo}
												</div>
												<div className="form-group">
													<div className="" style={{"borderRadius":"10px","background":"#FEE9B2","border":"1px solid #f3f5f9","display":"flex","flexDirection":"column"}}>
														{
															config_website.naptien_chuy == "" ?
																<Fragment>
																	<h6 className="font-bold text-danger mb-0" style={{"padding":"20px"}}>Chú ý:
																	<br></br>- Nạp sai cú pháp hoặc sai số tài khoản sẽ bị trừ 10% phí giao dịch, tối đa trừ 50.000 Coin. Ví dụ nạp sai 100.000 trừ 10.000, 200.000 trừ 20.000, 500.000 trừ 50.000, 1 triệu trừ 50.000, 10 triệu trừ 50.000...
																	<br></br>- Số tiền chuyển khoản tối thiểu 1 lần là <span><NumberFormat value={listbankpayment.minmoney} displayType={'text'} thousandSeparator={true} suffix={' VNĐ'} /></span>
																	</h6>
																</Fragment>
															: 
																<Fragment>
																	<h6 className="font-bold text-danger mb-0" style={{"padding":"20px", "whiteSpace": "pre-wrap"}}>{config_website.naptien_chuy}</h6>
																</Fragment>
														}
													</div>
												</div>
											</div>
                                        </div>
                                    </div>
                                :
                                    <div className='tab-pane fade fade-left show active show active'>
                                        <History />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage,
    buffviewsvideo: state.buffviewsvideo
});

export default connect(mapStateToProps, { listBankPayment, getlistBankInfoAdmin }) (NapTien);
