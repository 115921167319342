import React, { Component,Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { saveDataUser,loadInfoUser,updateUserInfo,updateConfig2FAData,loadConfig2FA,getID,sendVerifyEmail,enable2FA,disable2FA,confirm2FA } from '../../actions/adminPageAction';
class info_user extends Component {
	state = {
		// formdata: {
			// name_user: '',
			// email_user: '',
			// phone_user: '',
			// idfacebook_user: '',
			// old_pass_user: '',
			// re_pass_user: '',
			// new_pass_user: '',
		// },
		// loadding_page: true,
		email_user_tmp: '',
		changetab: 0,
		confirm_maxacminh: "",
	}

	componentDidMount(){
        // this.props.loadInfoUser().then(res =>{
        	// this.setState({
				// formdata: {
					// ...this.state.formdata,
					// name_user: res.data.username,
					// email_user: res.data.email !== null ? res.data.email : '',
					// phone_user: res.data.phone !== null ? res.data.phone : '',
					// idfacebook_user: res.data.facebook_id !== null ? res.data.facebook_id : '',
				// },
				// loadding_page: false,
			// });
        // });
		this.props.loadInfoUser().then(res =>{
			const { user_info } = this.props.adminpage;
			var new_user_info = {
				...user_info,
				name_user: res.data.username,
				email_user: res.data.email !== null ? res.data.email : '',
				phone_user: res.data.phone !== null ? res.data.phone : '',
				idfacebook_user: res.data.facebook_id !== null ? res.data.facebook_id : '',
				verify_email: res.data.verify_email !== null ? res.data.verify_email : '0',
				config2fa: res.data.config2fa !== null ? res.data.config2fa : '0',
			};
			this.props.updateUserInfo(new_user_info);
			this.setState({
				email_user_tmp: res.data.email !== null ? res.data.email : ''
			});
        });
		this.props.loadConfig2FA().then(res =>{
			const { config2fa_data } = this.props.adminpage;
			var new_config2fa_data = {
				...config2fa_data,
				config2fa_secret: res.data.config2fa_secret !== null ? res.data.config2fa_secret : '',
				config2fa_uri: res.data.config2fa_uri !== null ? res.data.config2fa_uri : '',
				config2fa_qr: res.data.config2fa_qr !== null ? res.data.config2fa_qr : '',
			};
			this.props.updateConfig2FAData(new_config2fa_data);
        });
    }

	ChangeInfoUser = (e) => {
		// this.setState({
			// formdata: {
				// ...this.state.formdata,
				// [e.target.name]: e.target.value
			// }
		// });
		
		if ((e.target.name === "idfacebook_user") && (e.target.value.indexOf("facebook.com") > -1)) {
			if (e.target.value !== "") {
				const { user_info } = this.props.adminpage;
				var new_user_info = {
					...user_info,
					[e.target.name]: e.target.value
				};
				this.props.getID(new_user_info);
			}
		} else {
			const { user_info } = this.props.adminpage;
			var new_user_info = {
				...user_info,
				[e.target.name]: e.target.value
			};
			this.props.updateUserInfo(new_user_info);
		}
	}
	onClick = (value) => {
		this.setState({
			changetab: value
		});
	};
	Change2FA = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {name_user,email_user,phone_user,idfacebook_user,old_pass_user,re_pass_user,new_pass_user,verify_email,config2fa} = this.props.adminpage.user_info;
		const {config2fa_secret,config2fa_uri,config2fa_qr} = this.props.adminpage.config2fa_data;
		return (
			<Fragment>
				<div className="row">
                    <div className="col-md-12">
                        <div className={"block block-bordered shadow" + (this.props.adminpage.loadingblock? ' block-mode-loading' : '')}>
							<div className="card-header-cus border-bottom">
								<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
									<li className="nav-item zxc licus">
										<Link className={"nav-link " + (this.state.changetab === 0 ? "active": "" )} to="/info-user" onClick={() => { this.onClick(0) }} >
											Thông tin cá nhân
										</Link>
									</li>
									<li className="nav-item zxc licus">
										<Link className={"nav-link " + (this.state.changetab === 1 ? "active": "" )} to="/info-user" onClick={() => { this.onClick(1) }} >
											2FA
										</Link>
									</li>
								</ul>
							</div>
							<div className="block-content tab-content overflow-hidden">
								<div className={"tab-pane fade fade-left " + (this.state.changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
									<div className="block-content">
										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Tên tài khoản:</label></div>
											<div className="col-md-8">
												<input disabled type="text" className="form-control" value={name_user}/>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Email:</label></div>
											<div className={verify_email == 0 && this.state.email_user_tmp == email_user ? "col-md-6" : "col-md-8"}>
												<input name="email_user" type="text" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={email_user} style={{"borderColor": verify_email == 0 && this.state.email_user_tmp == email_user ? "red" : ""}} disabled={verify_email == 0 ? false : true} />
												{
													verify_email == 0 && this.state.email_user_tmp == email_user ?
														<h6 className="text-danger" style={{"marginTop": "5px", "marginRight": "5px"}}>Lưu ý: Xác minh mail để tăng tính bảo mật và lấy lại mật khẩu khi quên!</h6>
													: ""
												}
											</div>
											<div className="col-md-2" style={{"display" : verify_email == 0 && this.state.email_user_tmp == email_user ? "block" : "none"}}>
												<button onClick={() => {this.props.sendVerifyEmail(this.props.adminpage.user_info)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">Gửi xác minh</button>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Số điện thoại:</label></div>
											<div className="col-md-8">
												<input name="phone_user" type="text" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={phone_user}/>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Link profile hoặc ID facebook:</label></div>
											<div className="col-md-8">
												<input name="idfacebook_user" type="text" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={idfacebook_user}/>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Mật khẩu cũ:</label></div>
											<div className="col-md-8">
												<input autoComplete="new-password" name="old_pass_user" type="password" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={old_pass_user}/>
											</div>
										</div>
										
										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Mật khẩu mới:</label></div>
											<div className="col-md-8">
												<input autoComplete="new-password" name="new_pass_user" type="password" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={new_pass_user}/>
											</div>
										</div>

										<div className="row form-group">
											<div className="col-md-4"><label className="mt-2">Nhập lại mật khẩu mới:</label></div>
											<div className="col-md-8">
												<input autoComplete="new-password" name="re_pass_user" type="password" className="form-control" onChange={(e)=>{this.ChangeInfoUser(e)}} value={re_pass_user}/>
											</div>
										</div>

										<div className="row mt-4">
											<div className="col-md-12">
												<div className="form-group float-right w-100">
													<button onClick={() => {this.props.saveDataUser(this.props.adminpage.user_info)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">Lưu</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className={"tab-pane fade fade-left " + (this.state.changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
									<div className="row form-group">
										<div className="col-md-4"><label className="mt-2">Two-factor authentication (2fa):</label></div>
										{/* <div className="col-md-8">
											<div className="custom-control custom-radio custom-control-inline">
												<input value="1" type="radio" className="custom-control-input" id="config2fa_1" name="config2fa" checked={config2fa == "1"} onChange={(e)=>{this.ChangeInfoUser(e)}} />
												<label className="custom-control-label" htmlFor="config2fa_1" > Bật</label>
											</div>
											<div className="custom-control custom-radio custom-control-inline">
												<input value="0" type="radio" className="custom-control-input" id="config2fa_0" name="config2fa" checked={config2fa == "0"} onChange={(e)=>{this.ChangeInfoUser(e)}}/>
												<label className="custom-control-label" htmlFor="config2fa_0" > Tắt</label>
											</div>
										</div> */}
										{
											config2fa_secret == "" && config2fa_uri == "" && config2fa_qr == "" ?
												<div className="col-md-2">
													<button onClick={() => {this.props.enable2FA(this.props.adminpage.user_info)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">Tạo mã 2fa</button>
												</div>
											: ''
										}
										{
											config2fa_secret != "" && config2fa_uri != "" && config2fa_qr != "" ?
												<div className="row col-md-8">
													<div className="col-md-3" style={{"wordBreak": "break-all", "display": "inline-flex", "alignItems": "center"}}>
														<img src={config2fa_qr} />
													</div>
													<div className="col-md-3" style={{"wordBreak": "break-all", "display": "inline-flex", "alignItems": "center"}}>
														<span> ---------HOẶC---------- </span>
														{/* <span>{config2fa_uri}</span> */}
													</div>
													<div className="col-md-3" style={{"wordBreak": "break-all", "display": "inline-flex", "alignItems": "center"}}>
														<span>{config2fa_secret}</span>
													</div>
													<div className="col-md-3" style={{"display": "inline-flex", "alignItems": "center"}}>
														<button onClick={() => {this.props.disable2FA(this.props.adminpage.user_info)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">Tắt mã 2fa</button>
													</div>
												</div>
											: ''
										}
									</div>

									<div className="row form-group">
										<div className="col-md-4"></div>
										<div className="row col-md-8">
											<span>
												Hướng dẫn và tải app Google Authenticator: <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" rel="nofollow">iPhone</a> | <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en" target="_blank" rel="nofollow">Android</a>
											</span>
										</div>
									</div>

									{
										config2fa == "0" && config2fa_secret != "" && config2fa_uri != "" && config2fa_qr != "" ?
											<Fragment>
												<div className="row form-group">
													<div className="col-md-4"><label className="mt-2">Nhập mã xác minh:</label></div>
													<div className="col-md-8">
														<input name="confirm_maxacminh" type="text" className="form-control" onChange={(e)=>{this.Change2FA(e)}} value={this.state.confirm_maxacminh} style={{"borderColor": "red"}}/>
														<h6 className="text-danger" style={{"marginTop": "5px", "marginRight": "5px"}}>Lưu ý: Nhập mã xác minh lần đầu để kích hoạt chức năng 2FA lên!</h6>
													</div>
												</div>
												<div className="row mt-4">
													<div className="col-md-12">
														<div className="form-group float-right w-100">
															<button onClick={() => {this.props.confirm2FA(this.props.adminpage.user_info, this.state.confirm_maxacminh)}} type="button" className="btn btn-hero-primary btn-rounded btn-block">Lưu</button>
														</div>
													</div>
												</div>
											</Fragment>
										: ""
									}
								</div>
							</div>
                        </div>
                    </div>
                </div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
    adminpage: state.adminpage
});
export default connect(mapStateToProps, {saveDataUser,loadInfoUser,updateUserInfo,updateConfig2FAData,loadConfig2FA,getID,sendVerifyEmail,enable2FA,disable2FA,confirm2FA}) (info_user);