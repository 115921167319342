import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabBufflikesubcomment/taotientrinh";
import NhatKy from "./tabBufflikesubcomment/nhatky";

import { changeTab, loadBLSC } from '../../actions/bufflikesubcommentActions';

class Bufflikesubcomment extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTab(0);
	};
	onClick = (value) => {
		this.props.changeTab(value);
		this.props.loadBLSC();
	};
	render() {
		const { changetab } = this.props.bufflikesubcomment;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (this.props.bufflikesubcomment.loading? ' block-mode-loading' : '')}>
					<div className="card-header-cus border-bottom">
						<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/bufflikesubcomment" onClick={() => { this.onClick(0) }} >
									Tạo Tiến Trình
								</Link>
							</li>
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/bufflikesubcomment" onClick={() => { this.onClick(1) }} >
									Danh Sách Order
								</Link>
							</li>
						</ul>
					</div>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	bufflikesubcomment: state.bufflikesubcomment,
});
const mapDispatchToProps = { changeTab, loadBLSC };

export default connect(mapStateToProps, mapDispatchToProps)(Bufflikesubcomment);
