import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import { addFBBC, updateFBBC, dangnhapFBBC, loadPriceFBBC, CheckCookie, loadProxyFBBC } from '../../../actions/FacebookbotcmtActions';
import { add, checkCookieFb, loadProxyFb, updateSticker } from '../../../actions/FbbotActions';
import { LOADING_FBBC } from "../../../actions/types";
import Moment from 'react-moment';
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import ModalSticker from './modal_sticker';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFBBC: PropTypes.func.isRequired,
		updateFBBC: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		checkCookieFb: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
	};
	
	state = {
		isOpenModalSticker: false,
		"dntkfcc": "",
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		/*this.props.loadPriceFBBC();*/
		this.props.loadProxyFb('facebookbotcmt');
	};
	
    openModalSticker = (data = null) => {
		this.setState({
			...this.state,
			isOpenModalSticker: !this.state.isOpenModalSticker,
		});
		if (data !== null) {
			this.setState({
				...this.state,
				isOpenModalSticker: !this.state.isOpenModalSticker,
				id: data,
			});
		}
    }
	
	option_tgctt = () => {
		let ar_option_tgctt = [];

		for (let i = 0; i < 24; i++) {
			ar_option_tgctt.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_tgctt;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookbotcmt;
		if (e.target.name === "lnncx_type") {
			var type = taotientrinh.lnncx_type;
			if (type.indexOf(e.target.value) === -1) {
				type.push(e.target.value);
			} else {
				var position = type.indexOf(e.target.value);
				type.splice(position, 1);
			}
			var new_taotientrinh = {
				...taotientrinh,
				"lnncx_type": type
			};
			this.props.updateFBBC(new_taotientrinh);
		} else if (e.target.name === "ctkfcc") {
			var fun_check = this.props.checkCookieFb(e.target.value);
			var cookie_ctkfcc = e.target.value;
			store.dispatch({
				type: LOADING_FBBC,
				payload: true
			});
			if (fun_check !== false) {
				fun_check.then(res => {
					if (res.data.status === 200) {
						let data_res = res.data.data;
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": data_res.uid,
							"usernamefb": data_res.name,
							"ctkfcc": cookie_ctkfcc,
							"dtsg": data_res.dtsg,
						};
						this.props.updateFBBC(new_taotientrinh);
						store.dispatch({
							type: LOADING_FBBC,
							payload: false
						});
						Swal.fire({
							icon: 'success',
							html: 'Lấy thông tin thành công'
						});
					} else {
						store.dispatch({
							type: LOADING_FBBC,
							payload: false
						});
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: 'Cookie die hoặc không hợp lệ',
						});
						return false;
					}
				})
			} else {
				store.dispatch({
					type: LOADING_FBBC,
					payload: true
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #1',
				});
				store.dispatch({
					type: LOADING_FBBC,
					payload: false
				});
				return false;
			}
		} else if (e.target.name === "lnncx") {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
				"lnncx_type": [],
				"lnncx_tdmn": "200",
			};
			this.props.updateFBBC(new_taotientrinh);
		} else if (e.target.name === "blbv") {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
				"blbv_cmt": "",
				"blbv_tdmn": "100",
				"sticker": [],
				"commentanh": "",
				"ca_check": "0",
				"s_check": "0",
				"sticker_pack":{},
				"newapi": "0",
			};
			this.props.updateFBBC(new_taotientrinh);
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateFBBC(new_taotientrinh);
		}
	}
	
	onChangeStickerAnh = (e) => {
		const { taotientrinh } = this.props.Facebookbotcmt;
		if (e.target.name === "ca_check") {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
				"s_check": "0",
				"sticker": [],
			};
			this.props.updateFBBC(new_taotientrinh);
			this.props.updateSticker('facebookbotcmt', []);
		} else if (e.target.name === "s_check") {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value,
				"ca_check": "0",
				"commentanh": "",
			};
			this.props.updateFBBC(new_taotientrinh);
		}
	}
	
	onClick = (data) => {
		const { taotientrinh } = this.props.Facebookbotcmt;
		var cmt_tmp = data;
		if (data === '|') {
			cmt_tmp = taotientrinh.blbv_cmt + data;
		} else if (data === '{icon}') {
			cmt_tmp = taotientrinh.blbv_cmt + '{icon' + Math.floor((Math.random() * 10) + 1) + '}';
		} else {
			cmt_tmp = taotientrinh.blbv_cmt + data;
		}
		var new_taotientrinh = {
			...taotientrinh,
			"blbv_cmt": cmt_tmp
		};
		this.props.updateFBBC(new_taotientrinh);
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookbotcmt;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.id_proxy === '0') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Bạn phải chọn 1 proxy!',
			});
			return false;
		}
		// if (taotientrinh.ctkfcc === '') {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// text: 'Cookie tài khoản facebook cần chạy không được để trống',
			// });
			// return false;
		// }
		if (parseInt(taotientrinh.tgctt_tu) > parseInt(taotientrinh.tgctt_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian chạy tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		if ((parseInt(taotientrinh.blbv_tu) > parseInt(taotientrinh.blbv_den)) && (taotientrinh.blbv === "1")) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian delay chuyển tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		
		if (taotientrinh.lnncx !== "1" && taotientrinh.blbv !== "1") {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Bạn phải chọn ít nhất 1 loại bot cảm xúc hoặc bình luận!',
			});
			return false;
		}
		
		if (taotientrinh.lnncx === "1" && taotientrinh.lnncx_type.length === 0) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Bạn phải chọn cảm xúc ít nhất 1 cảm xúc!',
			});
			return false;
		}
		if (taotientrinh.blbv !== "1" && taotientrinh.blbv_cmt != '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Bạn phải BẬT bình luận bài viết nếu có nội dung bình luận! ',
			});
			return false;
		}
		if (taotientrinh.blbv === "1" && taotientrinh.blbv_cmt === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Nội dung bình luận không được để trống nếu bạn BẬT bình luận bài viết!',
			});
			return false;
		}
		// var review = [];
		// // var check_reviewlength = 0;
		// if (taotientrinh.blbv_cmt.trim() !== "") {
		// 	var res1 = taotientrinh.blbv_cmt.trim().match(/[!@$%^&*;':\\/?~]/g);
		// 	var res2 = taotientrinh.blbv_cmt.trim().toLowerCase().match(/dm|đm|đ m|d m|địt mẹ|dit me|lol|lừađảo|conchó|trảtiền|mấtdạy|lừa đảo|con chó|trả tiền|mất dạy|lua dao|con cho|tra tien|mat day/g);
		// 	if (res1 !== null) {
		// 		if (res1.length > 0) {
		// 			Swal.fire({
		// 				icon: 'error',
		// 				title: 'Lỗi',
		// 				html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res1[0] + '</span>',
		// 			});
		// 			return false;
		// 		}
		// 	}
		// 	if (res2 !== null) {
		// 		if (res2.length > 0) {
		// 			Swal.fire({
		// 				icon: 'error',
		// 				title: 'Lỗi',
		// 				html: 'Nội dung Review thứ 1 không được chứa ký tự đặc biệt: <span class="text-danger font-bold">' + res2[0] + '</span>',
		// 			});
		// 			return false;
		// 		}
		// 	}
		// 	review = taotientrinh.blbv_cmt.trim();
		// 	// review.forEach(function(v, i){
		// 		// if (v.length < 25) {
		// 			// check_reviewlength = 1;
		// 		// }
		// 	// });
		// }
		// if (check_reviewlength === 1) {
			// Swal.fire({
				// icon: 'error',
				// title: 'Lỗi',
				// html: 'Nội dung của một review tối thiểu phải 25 ký tự',
			// });
			// return false;
		// }
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua chạy Auto tương tác trong "+taotientrinh.snmcatt+" ngày với giá "+taotientrinh.gtmtt+" Coin / ngày? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookbotcmt', new_taotientrinh);
			}
		})
	}
	
	onChangeDangNhap = (e) => {
		var dangnhap = {
			"dntkfcc": this.state.dntkfcc
		};
		this.props.dangnhapFBBC(dangnhap);
	}
	
	onChangedntkfcc = (e) => {
		this.setState({
			"dntkfcc": e.target.value
		});
	}
	
	formatCheckTime(date_end) {
		var result = new Date(date_end* 1000);
		var result_now = new Date();
		
		var difference = result - result_now;
		if (difference >= 0) {
			var daysDifference = Math.floor(difference/1000/60/60/24);
			return 'Còn ' + daysDifference + ' ngày';
		} else {
			return 'Hết hạn';
		}
	}
	
	option_proxy = () => {
		const { listproxy } = this.props.Facebookbotcmt;
		let ar_option_proxy = [];

		for (let i = 0; i < listproxy.length; i++) {
			if (this.formatCheckTime(listproxy[i].date_end) !== 'Hết hạn') {
				ar_option_proxy.push(
					<option value={listproxy[i].id} key={i} data-port={listproxy[i].port} data-username={listproxy[i].user} data-password={listproxy[i].pass}>
						{listproxy[i].note} ({listproxy[i].host}) ({this.formatCheckTime(listproxy[i].date_end)})
					</option>
				)	
			}
		}
		return ar_option_proxy;
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { ctkfcc, tgctt_tu, tgctt_den, tgdctt_tu, tgdctt_den, lnncx, lnncx_type, lnncx_tdmn, blbv, blbv_cmt, blbv_tdmn, snmcatt, gtmtt, idfb, usernamefb, ttv, bvtp, gioitinh, listid, commentanh, ca_check, s_check, sticker_pack, newapi } = this.props.Facebookbotcmt.taotientrinh;
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var price = Math.round((snmcatt * gtmtt + Number.EPSILON) * 100) / 100;
		var info = '';
		if (idfb !== '') {
			info = parse('<img src="https://graph.facebook.com/'+idfb+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user" /> ' + usernamefb + ' (' + idfb + ')');
		}
		var type_tuongtacvoi = '';
		if (ttv == 'LISTUIDPROFILE') {
			type_tuongtacvoi = 'Profile';
		}
		if (ttv == 'LISTUIDNHOM') {
			type_tuongtacvoi = 'Nhóm';
		}
		var listStickerPackage = {};
		try {
			if (localStorage.getItem("listStickerPackage") != null) {
				listStickerPackage = JSON.parse(localStorage.getItem("listStickerPackage"));
			} else {
				listStickerPackage = this.props.adminpage.listStickerPackage;
			}
		} catch(e) {
			listStickerPackage = [];
		}
		var sticker = '';
		var that = this;
		if (listStickerPackage.length > 0) {
			listStickerPackage.pop();
			sticker = listStickerPackage.map(function(v, i) {
				return <Link to='/tool/facebookbotcmt' key={i} onClick={() => {that.openModalSticker(v.id_sticker_package)}} className="btn text-center p-2 mr-1 col-lg-2" style={{"border": (typeof sticker_pack[v.id_sticker_package] !== 'undefined') ? "3px solid green" : '0px solid green'}}><img src={process.env.PUBLIC_URL + '/images/stickerfb/package_sticker/'+v.id_sticker_package+'.png'}  width="50" height="50" /><br/>{v.name}</Link>
			})
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_cmt = '';
		if (typeof(listDataServicesInfo.facebook_bot_cmt_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_bot_cmt_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_bot_cmt_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_bot_cmt) !== 'undefined') {
			html_cmt = parse(listDataServicesInfo.facebook_bot_cmt.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_bot_cmt.ghichu);
			}
		}
		
		return (
			<Fragment>
				{/*<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Đăng nhập tài khoản facebook cần chạy:</label>
					<div className="col-sm-6">
						<input type="text" className="form-control" id="dntkfcc" name="dntkfcc" placeholder="username|password" onChange={this.onChangedntkfcc} />
						<h6 className="mb-0 font-13 text-muted">Nhập tài khoản Facebook theo định dạng <span className="text-danger">username|password</span></h6>
					</div>
					<div className="col-sm-2">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.onChangeDangNhap} >Lấy Cookie</button>
					</div>
				</div>*/}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Cookie tài khoản facebook cần chạy:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="ctkfcc" name="ctkfcc" placeholder="" onChange={this.onChange} />
						{info}
					</div>
				</div>
				{/* <div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian delay chuyển tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_tu" name="tgdctt_tu" className="form-control input-light" onChange={this.onChange} value={tgdctt_tu} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_den" name="tgdctt_den" className="form-control input-light" onChange={this.onChange} value={tgdctt_den} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
						</div>
					</div>
				</div> */}
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Tương tác với:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="ttv" name="ttv" onChange={this.onChange} value={ttv} >
							<option value="FRIEND">Chỉ bài viết của bạn bè</option>
							<option value="NEWFEED">Tất cả bài viết trên newfeed</option>
							<option value="FRIEND_GROUP">Chỉ bài viết bạn bè và nhóm</option>
							<option value="LISTUIDPROFILE">Theo list ID profile</option>
							<option value="LISTUIDNHOM">Theo list ID nhóm</option>
						</select>
					</div>
				</div>
				{
					ttv == 'FRIEND' ?
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Giới tính:
							</label>
							<div className="col-sm-8">
								<div className="form-group">
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="all" type="radio" className="custom-control-input" id="banbe_0" name="gioitinh" checked={gioitinh === 'all'} />
										<label className="custom-control-label" htmlFor="banbe_0" >Tất cả</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="nam" type="radio" className="custom-control-input" id="banbe_1" name="gioitinh" checked={gioitinh === 'nam'}/>
										<label className="custom-control-label" htmlFor="banbe_1" >Chỉ nam</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="nu" type="radio" className="custom-control-input" id="banbe_2" name="gioitinh" checked={gioitinh === 'nu'}/>
										<label className="custom-control-label" htmlFor="banbe_2" >Chỉ nữ</label>
									</div>
								</div>
							</div>
						</div>
					: ''
				}
				{
					ttv == 'LISTUIDPROFILE' ||ttv == 'LISTUIDNHOM' ?
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								List ID {type_tuongtacvoi}:
							</label>
							<div className="col-sm-8">
								<div className="form-group">
									<div className="form-group">
										<textarea rows="2" type="text" className="form-control" id="listid" name="listid" placeholder={"Nhập list ID "+type_tuongtacvoi+" bạn muốn chạy BOT tương tác, ngăn cách nhau bởi dấu , (Vd : 100047535830919,100047535830919)"} onChange={this.onChange} ></textarea>
									</div>
								</div>
							</div>
						</div>
					: ''
				}
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Bài viết/phút:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="bvtp" name="bvtp" onChange={this.onChange} value={bvtp} >
							<option value="1">Tương tác 1 bài viết mỗi 1 đến 15 phút</option>
							<option value="2">Tương tác 2 bài viết mỗi 1 đến 15 phút</option>
							<option value="3">Tương tác 3 bài viết mỗi 1 đến 15 phút</option>
							<option value="4">Tương tác 4 bài viết mỗi 1 đến 15 phút</option>
							<option value="5">Tương tác 5 bài viết mỗi 1 đến 15 phút</option>
							<option value="10">Tương tác 10 bài viết mỗi 1 đến 15 phút</option>
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						 <div className="custom-control custom-checkbox custom-control-inline">
							<input value={lnncx === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="lnncx" name="lnncx" onChange={this.onChange} checked={lnncx === "1"} />
							<label className="custom-control-label" htmlFor="lnncx" >Like ngẫu nhiên cảm xúc:</label>
						</div>
						<h6 className="mb-0 font-13 text-danger">Có thể chọn nhiều loại cảm xúc</h6>
					</div>
					<div className="col-md-8">
						<div className="card card-gray">
							<div className="card-body py-2">
								{
									lnncx === '1' ?
										<Fragment>
											<div className="form-group">
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="like" checked={lnncx_type.indexOf("like") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb"  style={{"width": "52px"}}>
													<input style={{display: 'none'}} value="care" checked={lnncx_type.indexOf("care") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="love" checked={lnncx_type.indexOf("love") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="haha" checked={lnncx_type.indexOf("haha") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="wow" checked={lnncx_type.indexOf("wow") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="sad" checked={lnncx_type.indexOf("sad") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
												<label className="icon_feed_fb">
													<input style={{display: 'none'}} value="angry" checked={lnncx_type.indexOf("angry") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
													<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
												</label>
											</div>
											<div className="form-group" style={{ "marginBottom" : "0px" }}>
												<div className="row align-items-center">
													<div className="col-auto">
														<h6 className="mb-0">Tối đa 1 ngày:</h6>
													</div>
													<div className="col-4">
														<div className="input-group">
															<input type="number" id="lnncx_tdmn" name="lnncx_tdmn" className="form-control input-light" onChange={this.onChange} value={lnncx_tdmn} onKeyPress={this.onKeyPress} />
														</div>
													</div>
													<div className="col text-left">
														<h6 className="mb-0">Cảm xúc</h6>
													</div>
												</div>
											</div>
										</Fragment>
									: <span className="text-danger">Like ngẫu nhiên cảm xúc hiện đang tắt</span>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-2">
					<div className="col-md-4">
						<div className="custom-control custom-checkbox custom-control-inline">
							<input value={blbv === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="blbv" name="blbv" onChange={this.onChange} checked={blbv === "1"} />
							<label className="custom-control-label" htmlFor="blbv" >Bình luận bài viết:</label>
						</div>
						<h6 className="mb-0 font-13">Nên chọn ngẫu nhiên từ 1 đến 5</h6>
					</div>
					<div className="col-md-8">
						<div className="card card-gray">
							<div className="card-body py-2">
								{
									blbv === '1' ?
										<Fragment>
											<div className="form-group">
												<div className="custom-control custom-checkbox custom-control-inline">
													<input value={newapi === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="newapi" name="newapi" onChange={this.onChange} checked={newapi === "1"} />
													<label className="custom-control-label" htmlFor="newapi" >Sử dụng API cmt new (nếu nick bạn không chạy được API cũ(có lịch sử mà không có cmt) hãy tích vào đây)</label>
												</div>
											</div>
											<div className="form-group">
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{sticker}")}}>{"{"}sticker{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{icon}")}}>{"{"}icon{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{name}")}}>{"{"}name{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{tag}")}}>{"{"}tag{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{dongho}")}}>{"{"}dongho{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{anhrandom}")}}>{"{"}anhrandom{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{xinchao}")}}>{"{"}xinchao{"}"}</span>
												<span className="badge badge-primary mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("{anhtuychinh}")}}>{"{"}anhtuychinh{"}"}</span>
												<span className="badge badge-danger mb-2 mr-2" style={{"fontSize": "100%", "cursor": "pointer"}} onClick={(e) => {this.onClick("|")}}>Dấu | nội dung mới</span>
												<textarea rows="2" type="text" className="form-control" id="blbv_cmt" name="blbv_cmt" placeholder="Nhập nội dung muốn bot tự động bình luận bài viết mới nhất của bạn bè" onChange={this.onChange} value={blbv_cmt}></textarea>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}sticker{"}"} = để sử dụng sticker (nếu bạn không dùng 'Sticker tùy chỉnh' chúng tôi sẽ để nó random các sticker có trên hệ thống)</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}icon1{"}"}{"{"}icon2{"}"}->{"{"}icon10{"}"} = random emoij</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}name{"}"} = tên facebook chủ post</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}tag{"}"} = tag chủ post vào comment</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}dongho{"}"} = lấy thời gian hiện tại</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}anhrandom{"}"} = nếu muốn random ảnh con HEO,CHUỘT kèm tên chủ bài viết</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}xinchao{"}"} = nếu muốn random ảnh con HEO,CHUỘT kèm Xin Chào! tên chủ bài viết</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}anhtuychinh{"}"} = comment bằng ảnh của bạn ở mục Ảnh tùy chỉnh</h6>
												<h6 className="mb-0 mt-2 text-muted">Nhập: {"{"}my_tag100001234:Hằng Phạm{"}"} = tag người hoặc trang muốn gắn thêm vào comment (trong đó ví dụ 100001234 uid người được tag, Hằng Phạm tên người được tag, tự thay uid người cần tag vào! ) !</h6>
												<h6 className="mb-0 mt-2 text-muted">Lưu ý: Nếu bạn muốn chạy nhiều nội dung khác nhau thì mỗi nội dung cách nhau dấu <span className="text-danger"> | </span> Ví dụ: <span className="text-danger"> nội dung cmt 1|nội dung cmt 2|{"{"}sticker{"}"} </span></h6>
											</div>
											<div className="form-group" style={{ "marginBottom" : "0px" }}>
												<div className="row align-items-center">
													<div className="col-auto">
														<h6 className="mb-0">Tối đa 1 ngày:</h6>
													</div>
													<div className="col-4">
														<div className="input-group">
															<input type="number" id="blbv_tdmn" name="blbv_tdmn" className="form-control input-light" onChange={this.onChange} value={blbv_tdmn} onKeyPress={this.onKeyPress} />
														</div>
													</div>
													<div className="col text-left">
														<h6 className="mb-0">Bình luận</h6>
													</div>
												</div>
											</div>
										</Fragment>
									: <span className="text-danger">Bình luận bài viết hiện đang tắt</span>
								}
								
							</div>
						</div>
					</div>
				</div>
				{
					blbv === '1' ?
						<Fragment>
							<div className="row mt-2">
								<div className="col-md-4">
									<div className="custom-control custom-checkbox custom-control-inline">
										<input value={s_check === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="s_check" name="s_check" onChange={this.onChangeStickerAnh} checked={s_check === "1"} />
										<label className="custom-control-label" htmlFor="s_check" >Sticker tùy chỉnh:</label>
									</div>
								</div>
								<div className="col-md-8">
									{
										s_check === '1' ?
											<Fragment>
												<div className="col-md-12" style={{"height": "200px", "overflow": "auto", "border": "1px solid #cecece"}}>
													{sticker}
												</div>
											</Fragment>
										: <span className="text-danger">Sticker hiện đang random. Bật nếu muốn dùng lệnh {"{"}sticker{"}"} theo ý muốn</span>
									}
								</div>
							</div>
							<div className="row mt-2">
								<div className="col-md-4">
									<div className="custom-control custom-checkbox custom-control-inline">
										<input value={ca_check === "1" ? "0" : "1"} type="checkbox" className="custom-control-input" id="ca_check" name="ca_check" onChange={this.onChangeStickerAnh} checked={ca_check === "1"} />
										<label className="custom-control-label" htmlFor="ca_check" >Ảnh tùy chỉnh:</label>
									</div>
								</div>
								<div className="col-md-8">
									{
										ca_check === '1' ?
											<Fragment>
												<input type="text" id="commentanh" name="commentanh" className="form-control input-light" onChange={this.onChange} value={commentanh} placeholder="Nhập url hình ảnh muốn comment (vd: https://upanh.com/anhdep.jpg)" />
												<h6 className="mb-0 mt-2">Up ảnh lên: <a href="https://imgur.com/upload" target="_blank" rel="noopener noreferrer">imgur.com</a> (lấy URL của ảnh đuôi .jpg hoặc .png là được nhé)</h6>
												<h6 className="mb-0 mt-2 text-danger">Nếu chạy comment ảnh vui lòng không được chọn Sticker</h6>
											</Fragment>
										: <span className="text-danger">Comment ảnh hiện đang tắt. Bật nếu muốn dùng lệnh {"{"}anhtuychinh{"}"}</span>
									}
								</div>
							</div>
						</Fragment>
					: ''
				}
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian chạy tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_tu" name="tgctt_tu" onChange={this.onChange} value={tgctt_tu} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_den" name="tgctt_den" onChange={this.onChange} value={tgctt_den} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Số ngày mua chạy Auto tương tác:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="snmcatt" name="snmcatt" onChange={this.onChange} value={snmcatt} >
							<option value="10">10</option>
							<option value="30">30</option>
							<option value="60">60</option>
							<option value="90">90</option>
						</select>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Black List Từ Khóa:
					</label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="form-group">
								<textarea rows="2" type="text" className="form-control" id="blacklisttukhoa" name="blacklisttukhoa" placeholder="Nhập list từ khóa có chứa trong bài viết mà bạn không muốn BOT chạy tương tác, ngăn cách nhau bởi dấu , (Vd : buồn, đám tang, chia buồn)" onChange={this.onChange} ></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Black List ID:
					</label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="form-group">
								<textarea rows="2" type="text" className="form-control" id="blacklistid" name="blacklistid" placeholder="Nhập list ID bạn không muốn BOT chạy tương tác, ngăn cách nhau bởi dấu , (Vd : 100047535830919,100047535830919)" onChange={this.onChange} ></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Proxy:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="id_proxy" name="id_proxy" onChange={this.onChange} >
							<option value="0" data-port="" data-username="" data-password="">--</option>
							{this.option_proxy()}
						</select>
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Nên mua IP riêng để sử dụng BOT Tương Tác không bị checkpoint, 1 IP chỉ nên dùng cho 1 -> 3 tài khoản Facebook!<br/>
							Mua proxy: <a href="/tool/buy_proxy" target="_blank" className="font-bold"> Tại đây</a>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_bot_cmt" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ chạy Auto tương tác trong  <span className="green">{snmcatt} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / ngày</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
				{this.state.isOpenModalSticker ? <ModalSticker openModal={this.openModalSticker} isOpenModal={this.state.isOpenModalSticker} dataid={this.state.id} /> : ''}
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookbotcmt: state.Facebookbotcmt,
});
const mapDispatchToProps = { addFBBC, updateFBBC, dangnhapFBBC, loadPriceFBBC, CheckCookie, loadProxyFBBC, add, checkCookieFb, loadProxyFb, updateSticker }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);