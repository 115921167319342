import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TaoTientrinh from "./tabFacebookvipcomment/taotientrinh";
import NhatKy from "./tabFacebookvipcomment/nhatky";
import DanhSach from "./tabFacebookvipcomment/danhsach";

import { changeTab, loadFVC } from '../../actions/FacebookvipcommentActions';
import { load, changeTabFbVip } from '../../actions/FbvipActions';

class Facebookvipcomment extends Component {
	static propTypes =  {
		changeTab: PropTypes.func.isRequired,
		loadFVC: PropTypes.func.isRequired,
		changeTabFbVip: PropTypes.func.isRequired,
	};
	componentDidMount() {
		this.props.changeTabFbVip('facebookvipcomment', 0);
	};
	onClick = (value) => {
		this.props.changeTabFbVip('facebookvipcomment', value);
		// this.props.loadFVC();
		this.props.load('facebookvipcomment');
	};
	render() {
		const { changetab, loading } = this.props.Facebookvipcomment;
		return (
			<Fragment>
				<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
					<div className="card-header-cus border-bottom">
						<ul className="nav nav-tabs nav-tabs-block" data-toggle="tabs" role="tablist" >
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 0 ? "active": "" )} to="/tool/facebookvipcomment" onClick={() => { this.onClick(0) }} >
									Tạo Tiến Trình
								</Link>
							</li>
							{/*<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 2 ? "active": "" )} to="/tool/facebookvipcomment" onClick={() => { this.onClick(2) }} >
									Danh sách tài khoản
								</Link>
							</li>*/}
							<li className="nav-item zxc licus">
								<Link className={"nav-link " + (changetab === 1 ? "active": "" )} to="/tool/facebookvipcomment" onClick={() => { this.onClick(1) }} >
									Danh Sách Order
								</Link>
							</li>
						</ul>
					</div>
					<div className="block-content tab-content overflow-hidden">
						<div className={"tab-pane fade fade-left " + (changetab === 0 ? "show active": "" )} id="btabs-animated-slideleft-home" role="tabpanel" >
							<TaoTientrinh />
						</div>
						{/*<div className={"tab-pane fade fade-left " + (changetab === 2 ? "show active": "" )} id="btabs-animated-slideleft-list" role="tabpanel" >
							<DanhSach />
						</div>*/}
						<div className={"tab-pane fade fade-left " + (changetab === 1 ? "show active": "" )} id="btabs-animated-slideleft-profile" role="tabpanel" >
							<NhatKy />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookvipcomment: state.Facebookvipcomment,
});
const mapDispatchToProps = { changeTab, loadFVC, load, changeTabFbVip };

export default connect(mapStateToProps, mapDispatchToProps)(Facebookvipcomment);
