import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { listBankPayment, getlistBankInfoAdmin, updateNapTienThe, addNapTienThe, getAPITrumThe, checkAPITrumThe, getAPITrumTheFront } from '../../../../actions/adminPageAction';
import copy from 'copy-text-to-clipboard';
import History from './historythe';
import Swal from 'sweetalert2';
class NapTien extends Component {

    state = {
        checkTab: 0
    }
    componentDidMount(){
        // this.props.listBankPayment();
        // this.props.getlistBankInfoAdmin();
		// this.props.getAPITrumThe();
		this.props.getAPITrumTheFront();
		this.props.checkAPITrumThe();
    }
    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            checkTab: data,
        });
    }
    copyID(text) {
        copy(text);
        Swal.fire({
            icon: 'success',
            title: '',
            text: 'Đã copy nội dung chuyển tiền',
        });
        return false;
    }
	onChange = (e) => {
		const { naptienthe } = this.props.adminpage;
		if (e.target.name === 'loaithe') {
			var new_naptienthe = {
				...naptienthe,
				[e.target.name]: e.target.value,
				"menhgia": "10000"
			};
			this.props.updateNapTienThe(new_naptienthe);
		} else {
			var new_naptienthe = {
				...naptienthe,
				[e.target.name]: e.target.value
			};
			this.props.updateNapTienThe(new_naptienthe);
		}
	}
	taoTienTrinhfunc = () => {
		const { naptienthe } = this.props.adminpage;
		var new_naptienthe = {
			...naptienthe,
		};
		if (naptienthe.loaithe === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chưa chọn loại thẻ!',
			});
			return false;
		}
		if (naptienthe.menhgia === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Chưa chọn mệnh giá!',
			});
			return false;
		}
		if (naptienthe.seri === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Seri không được để trống!',
			});
			return false;
		}
		if (naptienthe.mathe === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Mã thẻ không được để trống!',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn nhập đúng loại, mệnh giá, seri, mã thẻ?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý mua',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.addNapTienThe(new_naptienthe);
			}
		})
	}
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
    render() {
        const {checkTab} = this.state;
        const { naptienthe, loadingblock, listbankpayment, chietkhau_thap_1, chietkhau_cao_1, checkapitrumthe, servernapthe, chietkhau_thap_2, chietkhau_cao_2 } = this.props.adminpage;
		var final_price = 0;
		if (servernapthe == "1" || servernapthe == "") {
			var chietkhau = chietkhau_thap_1;
			if (naptienthe.menhgia != "10000" && naptienthe.menhgia != "20000" && naptienthe.menhgia != "30000") {
				chietkhau = chietkhau_cao_1;
			}
			if (chietkhau === '') {
				chietkhau = 34;
				if (naptienthe.menhgia != "10000" && naptienthe.menhgia != "20000" && naptienthe.menhgia != "30000") {
					chietkhau = 31;
				}
			}
		}
		if (servernapthe == "2") {
			var chietkhau = chietkhau_thap_2;
			if (naptienthe.menhgia != "10000" && naptienthe.menhgia != "20000" && naptienthe.menhgia != "30000") {
				chietkhau = chietkhau_cao_2;
			}
			if (chietkhau === '') {
				chietkhau = 34;
				if (naptienthe.menhgia != "10000" && naptienthe.menhgia != "20000" && naptienthe.menhgia != "30000") {
					chietkhau = 31;
				}
			}
			if (naptienthe.loaithe == "VMS") {
				chietkhau = parseInt(chietkhau) + 10;
			}
		}
		var khuyenmai = parseInt((listbankpayment.tile*100) - 100);
		var price =  naptienthe.menhgia - Math.floor((naptienthe.menhgia * chietkhau / 100));
		final_price =  price + Math.floor((price * khuyenmai / 100));
		
		var content = "";
		if (checkapitrumthe === 0) {
			content = (
				<Fragment>
					<div className="row">
						<div className="col-md-12">
							<div className={"block block-bordered shadow"+ (loadingblock? ' block-mode-loading' : '')}>
								<div className="card-header-cus border-bottom">
									<ul className="nav nav-tabs nav-tabs-block">
										<li onClick={(e) => {this.openTabMenu(e, 0)}} className="nav-item zxc licus">
											<a className={'nav-link'+ (checkTab === 0 ? ' active' : '')} href="/#">
												Nạp Tiền
											</a>
										</li>
									</ul>
								</div>
								<div className="block-content tab-content overflow-hidden">
									<h1 className="text-danger">Hiện chức năng này tạm dừng (CÓ THỂ BÁO LẠI ADMIN ĐỂ ADMIN MỞ)!</h1>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)
		}
		if (checkapitrumthe === 1) {
			content = (
				<Fragment>
					<div className="row">
						<div className="col-md-12">
							<div className={"block block-bordered shadow"+ (loadingblock? ' block-mode-loading' : '')}>
								<div className="card-header-cus border-bottom">
									<ul className="nav nav-tabs nav-tabs-block">
										<li onClick={(e) => {this.openTabMenu(e, 0)}} className="nav-item zxc licus">
											<a className={'nav-link'+ (checkTab === 0 ? ' active' : '')} href="/#">
												Nạp Tiền Thẻ
											</a>
										</li>
										<li onClick={(e) => {this.openTabMenu(e, 1)}} className="nav-item zxc licus">
											<a className={'nav-link' + (checkTab === 1 ? ' active' : '')} href="/#">
												Nhật Ký Nạp Tiền Thẻ
											</a>
										</li>
									</ul>
								</div>
								<div className="block-content tab-content overflow-hidden">
									{checkTab === 0 ? 
										<div className='tab-pane fade fade-left show active'>
											<div id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" className="tab-pane fade show active">
												<div className="form-group row mt-4">
													<label className="col-sm-4 col-form-label" htmlFor="">
														Loại thẻ:
													</label>
													<div className="col-sm-8">
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="VMS" type="radio" className="custom-control-input" id="loaithe_1" name="loaithe" checked={naptienthe.loaithe == "VMS"} />
															<label className="custom-control-label" htmlFor="loaithe_1" >Mobifone (bị trừ {naptienthe.loaithe != "VMS" && servernapthe == "2" ? parseInt(chietkhau) + 10 : chietkhau}%)</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="VTT" type="radio" className="custom-control-input" id="loaithe_2" name="loaithe" checked={naptienthe.loaithe == "VTT"} />
															<label className="custom-control-label" htmlFor="loaithe_2" >Viettel (bị trừ {naptienthe.loaithe == "VMS" && servernapthe == "2" ? parseInt(chietkhau) - 10 : chietkhau}%)</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="VNP" type="radio" className="custom-control-input" id="loaithe_3" name="loaithe" checked={naptienthe.loaithe == "VNP"} />
															<label className="custom-control-label" htmlFor="loaithe_3" >Vina (bị trừ {naptienthe.loaithe == "VMS" && servernapthe == "2" ? parseInt(chietkhau) - 10 : chietkhau}%)</label>
														</div>
													</div>
												</div>
												<div className="form-group row mt-4">
													<label className="col-sm-4 col-form-label" htmlFor="">
														Mệnh giá:
													</label>
													<div className="col-sm-8">
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="10000" type="radio" className="custom-control-input" id="menhgia_1" name="menhgia" checked={naptienthe.menhgia == "10000"} />
															<label className="custom-control-label" htmlFor="menhgia_1" >10,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="20000" type="radio" className="custom-control-input" id="menhgia_2" name="menhgia" checked={naptienthe.menhgia == "20000"} />
															<label className="custom-control-label" htmlFor="menhgia_2" >20,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="30000" type="radio" className="custom-control-input" id="menhgia_3" name="menhgia" checked={naptienthe.menhgia == "30000"} />
															<label className="custom-control-label" htmlFor="menhgia_3" >30,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="50000" type="radio" className="custom-control-input" id="menhgia_4" name="menhgia" checked={naptienthe.menhgia == "50000"} />
															<label className="custom-control-label" htmlFor="menhgia_4" >50,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="100000" type="radio" className="custom-control-input" id="menhgia_5" name="menhgia" checked={naptienthe.menhgia == "100000"} />
															<label className="custom-control-label" htmlFor="menhgia_5" >100,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="200000" type="radio" className="custom-control-input" id="menhgia_6" name="menhgia" checked={naptienthe.menhgia == "200000"} />
															<label className="custom-control-label" htmlFor="menhgia_6" >200,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="300000" type="radio" className="custom-control-input" id="menhgia_7" name="menhgia" checked={naptienthe.menhgia == "300000"} />
															<label className="custom-control-label" htmlFor="menhgia_7" >300,000</label>
														</div>
														<div className="custom-control custom-radio custom-control-inline">
															<input onChange={(e) => {this.onChange(e)}} value="500000" type="radio" className="custom-control-input" id="menhgia_8" name="menhgia" checked={naptienthe.menhgia == "500000"} />
															<label className="custom-control-label" htmlFor="menhgia_8" >500,000</label>
														</div>
														{
															naptienthe.loaithe !== "VMS" && naptienthe.loaithe !== "VNP" ?
																<div className="custom-control custom-radio custom-control-inline">
																	<input onChange={(e) => {this.onChange(e)}} value="1000000" type="radio" className="custom-control-input" id="menhgia_9" name="menhgia" checked={naptienthe.menhgia == "1000000"} />
																	<label className="custom-control-label" htmlFor="menhgia_9" >1,000,000</label>
																</div>
															: ''
														}
													</div>
												</div>
												<div className="form-group row mt-4">
													<label className="col-sm-4 col-form-label" htmlFor="">
														Số seri:
													</label>
													<div className="col-sm-8">
														<input type="number" className="form-control" id="seri" name="seri" placeholder="" value={naptienthe.seri} onChange={this.onChange} onKeyPress={this.onKeyPress} />
													</div>
												</div>
												<div className="form-group row mt-4">
													<label className="col-sm-4 col-form-label" htmlFor="">
														Mã thẻ:
													</label>
													<div className="col-sm-8">
														<input type="number" className="form-control" id="mathe" name="mathe" placeholder="" value={naptienthe.mathe} onChange={this.onChange} onKeyPress={this.onKeyPress} />
													</div>
												</div>
												<div className="form-group row mt-4">
													<label className="col-sm-4 col-form-label" htmlFor="">
													</label>
													<div className="col-md-8 bold">
														<div className="card card-orange">
															<div className="card-body py-2">
																<h3 className="mb-0 text-danger">HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG!</h3>
																<h4 className="mb-0 text-primary">Hãy chọn đúng mệnh giá và loại thẻ nếu chọn sai sẽ mất thẻ và không được hoàn lại!</h4>
																{/* <h4 className="mb-0 text-danger">VÍ DỤ:</h4>
																<h4 className="mb-0 text-primary">•	Thẻ mệnh giá 10.000, chọn 100.000 => duyệt tiền 10.000</h4>
																<h4 className="mb-0 text-primary">•	Thẻ mệnh giá 100.000, chọn 10.000 => duyệt tiền 10.000</h4> */}
															</div>
														</div>
													</div>
												</div>
												<div className="row mt-4">
													<div className="col-12 text-center bold">
														<div className="card card-info">
															<div className="card-body">
																<h5 className="font-bold">Nhận được: <span className="bold green"><span>{final_price}</span> Coin</span></h5>
																<h6 className="mb-0 bold">
																	Bạn nạp thẻ <span className="bold green">{naptienthe.menhgia}</span> với loại thẻ <span className="bold green">{naptienthe.loaithe == "VMS" ? 'Mobifone' : ''}{naptienthe.loaithe == "VTT" ? 'Viettel' : ''}{naptienthe.loaithe == "VNP" ? 'Vina' : ''}</span> với chiết khấu <span className="bold green">{chietkhau}%</span>
																</h6>
															</div>
														</div>
													</div>
												</div>
												<div className="row my-4">
													<div className="col-12 text-center bold">
														<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Nạp tiền</button>
													</div>
												</div>
											</div>
										</div>
									:
										<div className='tab-pane fade fade-left show active show active'>
											<History />
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)
		}
        return (
            <Fragment>
				{content}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    adminpage: state.adminpage,
});

export default connect(mapStateToProps, { listBankPayment, getlistBankInfoAdmin, updateNapTienThe, addNapTienThe, getAPITrumThe, checkAPITrumThe, getAPITrumTheFront }) (NapTien);
