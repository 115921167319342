import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { listSupport } from '../../../../actions/adminPageAction';
import { loadSP } from '../../../../actions/SupportActions';
import Add from "./add";
import Edit from "./edit";
import List from "./list";

class Support extends Component {

    state = {
        checkTab: 0
    }
    componentDidMount(){
       this.props.listSupport();
       this.props.loadSP();
    }
    openTabMenu = (e, data) => {
        e.preventDefault();
        this.setState({
            checkTab: data,
        });
    }

    render() {
        const { support } = this.props.adminpage;
        const { loading } = this.props.Support;
        return (
            <Fragment>
                <div className="row">
					<div className="col-md-12">
						<div className={"block block-bordered shadow"+ (loading? ' block-mode-loading' : '')}>
							<div className="card-header-cus border-bottom">
								<div className="card-header" style={{"textAlign": "center", "color": "#0061f2", "fontWeight": "700", "fontSize": "1.2rem"}}>Liên hệ hỗ trợ</div>
							</div>
							<div className="block-content tab-content overflow-hidden">
								<div className='tab-pane fade show active show active' id="nhatkyorder" >
									{
										typeof this.props.match.params.id !== 'undefined' ?
											<Fragment>
												{
													this.props.match.params.id === 'add' ?
														<Add />
													: 
													<Edit id={this.props.match.params.id} />
												}
											</Fragment>
										: <List />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminpage: state.adminpage,
    Support: state.Support,
});

export default connect(mapStateToProps, { listSupport, loadSP }) (Support);
