import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../../store';

import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import iconLikeFb from '../../../iconfb/like.svg';
import iconCareFb from '../../../iconfb/care.svg';
import iconHahaFb from '../../../iconfb/haha.svg';
import iconAngryFb from '../../../iconfb/angry.svg';
import iconLoveFb from '../../../iconfb/love.svg';
import iconSadFb from '../../../iconfb/sad.svg';
import iconWowFb from '../../../iconfb/wow.svg';
import { addFBBV, updateFBBV, dangnhapFBBV, loadPriceFBBV, CheckCookie } from '../../../actions/Facebookbotv2Actions';
import { add, checkCookieFb, loadProxyFb } from '../../../actions/FbbotActions';
import { LOADING_FBBV } from "../../../actions/types";
import parse from 'html-react-parser';
import ServicesDescription from "../ServicesDescription";
import { isMobile } from 'react-device-detect';

const date7dnext = () => {
	var result = new Date();
  	result.setDate(result.getDate() + 7);
  	return result;
}

class taotientrinh extends Component {
	static propTypes =  {
		addFBBV: PropTypes.func.isRequired,
		updateFBBV: PropTypes.func.isRequired,
		loadPriceFBBV: PropTypes.func.isRequired,
		CheckCookie: PropTypes.func.isRequired,
		checkCookieFb: PropTypes.func.isRequired,
		add: PropTypes.func.isRequired,
	};
	
	state = {
		"dntkfcc": "",
		showDescription: isMobile? false : true
	}
	
	componentDidMount() {
		// this.props.loadPriceFBBV();
		this.props.loadProxyFb('facebookbotv2');
	};
	
	option_tgctt = () => {
		let ar_option_tgctt = [];

		for (let i = 0; i < 24; i++) {
			ar_option_tgctt.push(
				<option value={i} key={i}>
					{i}
				</option>
			)
		}
		return ar_option_tgctt;
	}
	
	formatCheckTime(date_end) {
		var result = new Date(date_end* 1000);
		var result_now = new Date();
		
		var difference = result - result_now;
		if (difference >= 0) {
			var daysDifference = Math.floor(difference/1000/60/60/24);
			return 'Còn ' + daysDifference + ' ngày';
		} else {
			return 'Hết hạn';
		}
	}
	
	option_proxy = () => {
		const { listproxy } = this.props.Facebookbotv2;
		let ar_option_proxy = [];

		for (let i = 0; i < listproxy.length; i++) {
			if (this.formatCheckTime(listproxy[i].date_end) !== 'Hết hạn') {
				ar_option_proxy.push(
					<option value={listproxy[i].id} key={i} data-port={listproxy[i].port} data-username={listproxy[i].user} data-password={listproxy[i].pass}>
						{listproxy[i].note} ({listproxy[i].host}) ({this.formatCheckTime(listproxy[i].date_end)})
					</option>
				)	
			}
		}
		return ar_option_proxy;
	}
	
	onChange = (e) => {
		const { taotientrinh } = this.props.Facebookbotv2;
		if (e.target.name === "lnncx_type") {
			var type = taotientrinh.lnncx_type;
			if (type.indexOf(e.target.value) === -1) {
				type.push(e.target.value);
			} else {
				var position = type.indexOf(e.target.value);
				type.splice(position, 1);
			}
			var new_taotientrinh = {
				...taotientrinh,
				"lnncx_type": type
			};
			this.props.updateFBBV(new_taotientrinh);
		} else if (e.target.name === "ctkfcc") {
			var fun_check = this.props.checkCookieFb(e.target.value);
			var cookie_ctkfcc = e.target.value;
			store.dispatch({
				type: LOADING_FBBV,
				payload: true
			});
			if (fun_check !== false) {
				fun_check.then(res => {
					if (res.data.status === 200) {
						let data_res = res.data.data;
						var new_taotientrinh = {
							...taotientrinh,
							"idfb": data_res.uid,
							"usernamefb": data_res.name,
							"ctkfcc": cookie_ctkfcc,
							"dtsg": data_res.dtsg,
						};
						this.props.updateFBBV(new_taotientrinh);
						store.dispatch({
							type: LOADING_FBBV,
							payload: false
						});
						Swal.fire({
							icon: 'success',
							html: 'Lấy thông tin thành công'
						});
					} else {
						store.dispatch({
							type: LOADING_FBBV,
							payload: false
						});
						Swal.fire({
							icon: 'error',
							title: 'Lỗi',
							text: 'Cookie die hoặc không hợp lệ',
						});
						return false;
					}
				})
			} else {
				store.dispatch({
					type: LOADING_FBBV,
					payload: true
				});
				Swal.fire({
					icon: 'error',
					title: 'Lỗi',
					text: 'Cookie die hoặc không hợp lệ #1',
				});
				store.dispatch({
					type: LOADING_FBBV,
					payload: false
				});
				return false;
			}
		} else {
			var new_taotientrinh = {
				...taotientrinh,
				[e.target.name]: e.target.value
			};
			this.props.updateFBBV(new_taotientrinh);
		}
	}
	
	taoTienTrinhfunc = () => {
		const { taotientrinh } = this.props.Facebookbotv2;
		const id_user = this.props.auth.user.id;
		var new_taotientrinh = {
			...taotientrinh,
			"id_user": id_user
		};
		if (taotientrinh.ctkfcc === '') {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Cookie tài khoản facebook cần chạy không được để trống',
			});
			return false;
		}
		if (parseInt(taotientrinh.tgctt_tu) > parseInt(taotientrinh.tgctt_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian chạy tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		if (parseInt(taotientrinh.tgdctt_tu) > parseInt(taotientrinh.tgdctt_den)) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Thời gian delay chuyển tương tác bắt đầu phải nhỏ hơn kết thúc',
			});
			return false;
		}
		if ((parseInt(taotientrinh.blbv_tu) > parseInt(taotientrinh.blbv_den)) && (taotientrinh.blbv === "1")) {
			Swal.fire({
				icon: 'error',
				title: 'Lỗi',
				text: 'Icon bình luận bài viết từ phải nhỏ hơn đến',
			});
			return false;
		}
		Swal.fire({
			title: 'Bạn có chắc?',
			html: "Bạn có chắc chắn muốn mua chạy Auto tương tác trong "+taotientrinh.snmcatt+" ngày với giá "+taotientrinh.gtmtt+" Coin / ngày? Khi đã bấm đồng ý đồng nghĩa với việc bạn đã đọc hết mục thông tin dịch vụ!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Đồng ý',
			cancelButtonText: 'Không'
		}).then((result) => {
			if (result.value) {
				this.props.add('facebookbotv2', new_taotientrinh);
			}
		})
	}
	
	onChangeDangNhap = (e) => {
		var dangnhap = {
			"dntkfcc": this.state.dntkfcc
		};
		this.props.dangnhapFBBV(dangnhap);
	}
	
	onChangedntkfcc = (e) => {
		this.setState({
			"dntkfcc": e.target.value
		});
	}
	
	onKeyPress = (e) => {
		if (!(e.charCode >= 48 && e.charCode <= 57)) {
			e.preventDefault();
		}
	}
	
	onClickShowDescription = (data) => {
		this.setState({
			showDescription: data
		});
	}
	
	render() {
		const { ctkfcc, tgctt_tu, tgctt_den, tgdctt_tu, tgdctt_den, lnncx_type, lnncx_tdmn, snmcatt, gtmtt, idfb, usernamefb, ttv, bvtp, gioitinh } = this.props.Facebookbotv2.taotientrinh;
		var token_avt = {};
		try {
			if (localStorage.getItem("token_avt") != null) {
				token_avt = JSON.parse(localStorage.getItem("token_avt"))[0].token_avt;
			} else {
				token_avt = this.props.auth.token_avt[0].token_avt;
			}
		} catch(e) {
			token_avt = localStorage.getItem("token_avt");
		}
		var price = Math.round((snmcatt * gtmtt + Number.EPSILON) * 100) / 100;
		var info = '';
		if (idfb !== '') {
			info = parse('<img src="https://graph.facebook.com/'+idfb+'/picture?height=100&amp;width=100&access_token='+token_avt+'" class="avatar-user" /> ' + usernamefb + ' (' + idfb + ')');
		}
		
		var listDataServicesInfo = {};
		try {
			if (localStorage.getItem("listDataServicesInfo") != null) {
				listDataServicesInfo = JSON.parse(localStorage.getItem("listDataServicesInfo"));
			} else {
				listDataServicesInfo = this.props.adminpage.listDataServicesInfo;
			}
		} catch(e) {
			listDataServicesInfo = {};
		}
		var html_haydoctranhmattien = '';
		var html_haydoctranhmattien_chinh = '';
		var html_haydoctranhmattien_sv = '';
		var html_love = '';
		if (typeof(listDataServicesInfo.facebook_bot_love_tab) !== 'undefined') {
			html_haydoctranhmattien = parse(listDataServicesInfo.facebook_bot_love_tab.ghichu);
			html_haydoctranhmattien_chinh = parse(listDataServicesInfo.facebook_bot_love_tab.ghichu_chinh);
		}
		if (typeof(listDataServicesInfo.facebook_bot_love) !== 'undefined') {
			html_love = parse(listDataServicesInfo.facebook_bot_love.ghichu_sv);
			if ("1" === "1") {
				html_haydoctranhmattien_sv = parse(listDataServicesInfo.facebook_bot_love.ghichu);
			}
		}
		
		return (
			<Fragment>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Cookie tài khoản facebook cần chạy:</label>
					<div className="col-sm-8">
						<input type="text" className="form-control" id="ctkfcc" name="ctkfcc" placeholder="" onChange={this.onChange} />
						{info}
					</div>
				</div>
				{/* <div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian delay chuyển tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_tu" name="tgdctt_tu" className="form-control input-light" onChange={this.onChange} value={tgdctt_tu} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<input type="number" id="tgdctt_den" name="tgdctt_den" className="form-control input-light" onChange={this.onChange} value={tgdctt_den} onKeyPress={this.onKeyPress} />
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giây</h6>
							</div>
						</div>
					</div>
				</div> */}
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Tương tác với:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="ttv" name="ttv" onChange={this.onChange} value={ttv} >
							<option value="FRIEND">Chỉ bài viết của bạn bè</option>
							<option value="NEWFEED">Tất cả bài viết trên newfeed</option>
							<option value="FRIEND_GROUP">Chỉ bài viết bạn bè và nhóm</option>
						</select>
					</div>
				</div>
				{
					ttv == 'FRIEND' ?
						<div className="form-group row mt-4">
							<label className="col-sm-4 col-form-label" htmlFor="">
								Giới tính:
							</label>
							<div className="col-sm-8">
								<div className="form-group">
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="all" type="radio" className="custom-control-input" id="banbe_0" name="gioitinh" checked={gioitinh === 'all'} />
										<label className="custom-control-label" htmlFor="banbe_0" >Tất cả</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="nam" type="radio" className="custom-control-input" id="banbe_1" name="gioitinh" checked={gioitinh === 'nam'}/>
										<label className="custom-control-label" htmlFor="banbe_1" >Chỉ nam</label>
									</div>
									<div className="custom-control custom-radio custom-control-inline">
										<input onChange={this.onChange} value="nu" type="radio" className="custom-control-input" id="banbe_2" name="gioitinh" checked={gioitinh === 'nu'}/>
										<label className="custom-control-label" htmlFor="banbe_2" >Chỉ nữ</label>
									</div>
								</div>
							</div>
						</div>
					: ''
				}
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Bài viết/phút:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="bvtp" name="bvtp" onChange={this.onChange} value={bvtp} >
							<option value="1">Tương tác 1 bài viết mỗi 1 đến 15 phút</option>
							<option value="2">Tương tác 2 bài viết mỗi 1 đến 15 phút</option>
							<option value="3">Tương tác 3 bài viết mỗi 1 đến 15 phút</option>
							<option value="4">Tương tác 4 bài viết mỗi 1 đến 15 phút</option>
							<option value="5">Tương tác 5 bài viết mỗi 1 đến 15 phút</option>
							<option value="10">Tương tác 10 bài viết mỗi 1 đến 15 phút</option>
						</select>
					</div>
				</div>
				<div className="row mt-2">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Like ngẫu nhiên cảm xúc:
					</label>
					<div className="col-md-8">
						<div className="card card-gray">
							<div className="card-body py-2">
								<div className="form-group">
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="like" checked={lnncx_type.indexOf("like") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLikeFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb"  style={{"width": "52px"}}>
										<input style={{display: 'none'}} value="care" checked={lnncx_type.indexOf("care") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconCareFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="love" checked={lnncx_type.indexOf("love") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconLoveFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="haha" checked={lnncx_type.indexOf("haha") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconHahaFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="wow" checked={lnncx_type.indexOf("wow") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconWowFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="sad" checked={lnncx_type.indexOf("sad") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconSadFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
									<label className="icon_feed_fb">
										<input style={{display: 'none'}} value="angry" checked={lnncx_type.indexOf("angry") !== -1} onChange={this.onChange} type="checkbox" name="lnncx_type" />
										<img src={iconAngryFb} alt="icon fb" className="img-responsive image-reaction ml-2" />
									</label>
								</div>
								<div className="form-group" style={{ "marginBottom" : "0px", "display" : 'flex' }}>
									<div className="row align-items-center">
										<div className="col-auto">
											<h6 className="mb-0">Tối đa 1 ngày:</h6>
										</div>
										<div className="col-4">
											<div className="input-group">
												<input type="number" id="lnncx_tdmn" name="lnncx_tdmn" className="form-control input-light" onChange={this.onChange} value={lnncx_tdmn} onKeyPress={this.onKeyPress} />
											</div>
										</div>
										<div className="col text-left">
											<h6 className="mb-0">Cảm xúc</h6>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Thời gian chạy tương tác:
					</label>
					<div className="col-sm-8">
						<div className="row align-items-center">
							<div className="col-auto">
								<h6 className="bold mb-0">Từ:</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_tu" name="tgctt_tu" onChange={this.onChange} value={tgctt_tu} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto px-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
							<div className="col-auto">
								<h6 className="bold mb-0">đến</h6>
							</div>
							<div className="col">
								<select required="required" className="select-gray custom-select" id="tgctt_den" name="tgctt_den" onChange={this.onChange} value={tgctt_den} >
									{this.option_tgctt()}
								</select>
							</div>
							<div className="col-auto pl-0">
								<h6 className="bold mb-0">Giờ</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Số ngày mua chạy Auto tương tác:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="snmcatt" name="snmcatt" onChange={this.onChange} value={snmcatt} >
							<option value="10">10</option>
							<option value="30">30</option>
							<option value="60">60</option>
							<option value="90">90</option>
						</select>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Black List ID:
					</label>
					<div className="col-sm-8">
						<div className="form-group">
							<div className="form-group">
								<textarea rows="2" type="text" className="form-control" id="blacklistid" name="blacklistid" placeholder="Nhập list ID bạn muốn BOT không chạy tương tác, ngăn cách nhau bởi dấu , (Vd : 100047535830919,100047535830919)" onChange={this.onChange} ></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="form-group row mt-4">
					<label className="col-sm-4 col-form-label" htmlFor="">
						Proxy:
					</label>
					<div className="col-sm-8">
						<select className="form-control custom-select select-light" id="id_proxy" name="id_proxy" onChange={this.onChange} >
							<option value="0" data-port="" data-username="" data-password="">--</option>
							{this.option_proxy()}
						</select>
						<div className="alert alert-danger" style={{"marginTop": "5px"}}>
							<i className="fa fa-exclamation-triangle"></i> <strong>Lưu ý:</strong> Nên mua IP riêng để sử dụng BOT Tương Tác không bị checkpoint, 1 IP chỉ nên dùng cho 1 -> 3 tài khoản Facebook!<br/>
							Mua proxy: <a href="/tool/buy_proxy" target="_blank" className="font-bold"> Tại đây</a>
						</div>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label" htmlFor="">Ghi chú:</label>
					<div className="col-sm-8">
						<textarea rows="2" placeholder="Nhập nội dung ghi chú về tiến trình của bạn" className="form-control input-gray" id="gc" name="gc" onChange={this.onChange} ></textarea>
					</div>
				</div>
				
				<button type="button" className="btn badge-primary-cus btn-rounded btn-show-description-cus" onClick={(e) => {this.onClickShowDescription(!this.state.showDescription)}}>
					{
						this.state.showDescription?
							<i className="fa fa-angle-up" style={{"marginRight": "5px"}} aria-hidden="true"></i>
						:
							<i className="fa fa-angle-down" style={{"marginRight": "5px"}} aria-hidden="true"></i>
					}
					Thông tin dịch vụ
				</button>
				
				<ServicesDescription allservicesinfo={listDataServicesInfo} server={"1"} tab="facebook_bot_love" giatien={gtmtt} baohanh={0} showDescription={this.state.showDescription} />
				
				<div className="row mt-2" style={{"display": this.state.showDescription? "flex" : "none"}}>
				    <div className="col-md-12 bold" style={{"marginBottom": "10px"}}>
						<div className="card card-select-cus">
							<div className="card-body card-body-select-cus">
								<p className="mb-0 mt-3 text-wrap">
									<ul>
										{html_haydoctranhmattien}
										{html_haydoctranhmattien_chinh}
										{html_haydoctranhmattien_sv}
										<li>Nếu gặp lỗi hãy nhắn tin hỗ trợ phía bên phải góc màn hình hoặc vào mục liên hệ hỗ trợ để được hỗ trợ tốt nhất!</li>
									</ul>
								</p>
								<span className="badge badge-cus position-absolute-cus badge-top-left-cus badge-primary-cus badge-pill-cus"> HÃY ĐỌC ĐỂ TRÁNH MẤT TIỀN KHI SỬ DỤNG! </span>
							</div>
						</div>
				    </div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center bold">
						<div className="card card-info">
							<div className="card-body">
								<h5 className="font-bold">Tổng: <span className="bold green"><span>{price}</span> Coin</span></h5>
								<h6 className="mb-0 bold"><span>Bạn sẽ chạy Auto tương tác trong  <span className="green">{snmcatt} ngày</span> với giá <span className="green">{gtmtt}</span> Coin / ngày</span></h6>
							</div>
						</div>
					</div>
				</div>
				<div className="row my-4">
					<div className="col-12 text-center bold">
						<button type="button" className="btn btn-block btn-rounded btn-hero-primary" onClick={this.taoTienTrinhfunc} >Tạo tiến trình</button>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	Facebookbotv2: state.Facebookbotv2,
});
const mapDispatchToProps = { addFBBV, updateFBBV, dangnhapFBBV, loadPriceFBBV, CheckCookie, add, checkCookieFb, loadProxyFb }

export default connect(mapStateToProps, mapDispatchToProps)(taotientrinh);